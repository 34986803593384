import {jwtDecode }from 'jwt-decode';
import Cookies from 'js-cookie';

const getJwtTokenFromCookie = () => {
    const token = Cookies.get('jwt-token');
    if(token) return token;
    return null; 
}

export function getUserFromToken() {
  try {
    const storedData = Cookies.get("x-auth-token");
    if(!storedData) return;
    const stringData = JSON.parse(storedData);
    return stringData;

  } catch (error) {
    console.log("Error", error?.message);
  }
}

export function getConfirmationCode() {
  try {
    const storedData = Cookies.get("activationCode");
    if(!storedData) return;
    const confirmationData = JSON.parse(storedData);
    return confirmationData;

  } catch (error) {
    console.log("Error", error?.message);
  }
}

export function deleteConfirmationCode() {
  try {
    Cookies.remove("activationCode");

  } catch (error) {
    console.log("Error", error?.message);
  }
}



export default getJwtTokenFromCookie;