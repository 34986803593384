import React from "react";
import styled from "styled-components";
import home from "../assets/home-2.png";
import { H4 } from "./Common/typography";
import { FadeInNoSlide } from "./Common/animations";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: ${FadeInNoSlide} 0.5s;
`;
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  .info-text{
    text-align: center;
    width: 300px;
    font-weight: 400;
  }
`;
const Image = styled.img`
  width: 40%;
  height: auto;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 1rem;
`;

export default function EmptyPage() {
  return (
    <Container>
      <InnerContainer>
        <Image src={home} />
        <h3 style={{ color: "var(--secondary-text)" }}>
          Vi arbejder på noget fantastisk!
        </h3>
        <H4 className="info-text">
          Denne side er i øjeblikket under udvikling. Kom venligst snart tilbage
          for opdateringer.
        </H4>
      </InnerContainer>
    </Container>
  );
}
