import styled from 'styled-components';
import { MdOutlineClose } from 'react-icons/md';
import { baseButton } from './buttons';
import { Title } from './typography';
import { FadeInNoSlide } from './animations';
import { breakpoints } from '../../constants';


const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(0, 0, 0, .08);
    padding: 1.5rem;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  backdrop-filter: blur(2px); /* Add blur effect */

`;

const ModalContainer = styled.div`
    box-shadow: var(--cart-boxshadow);
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
    background-color: var(--color-white);
    border-radius: var(--cart-radius);
    min-height: ${({height}) => height || 'fit-content'};
    width: ${({ width }) => width ? width : "18rem"};
    margin:auto;
    padding: 1.5rem;
    animation: ${FadeInNoSlide} 0.4s ease;
    margin-top: ${({ marginTop }) => marginTop ? marginTop : 'auto'};
    border-radius: 10px;
    @media (min-width: ${breakpoints.md}) {
      width: 30rem;
    }

`;

export const CloseButton = styled.button`
    ${baseButton}
    padding: 0px;
    position: absolute;
    left: auto;
    right: 10px;
    top: 10px;
    z-index: 1;
    background-color: var(--color-blue);
    color: var(--color-white);
    height: 2rem;
    width: 2rem;
    margin-left: .5rem;
    border-radius: 50%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;



const Body = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const DefaultCloseButton = () => {
    return (
      <CloseButton>
          <MdOutlineClose fontSize={20}/>
      </CloseButton>
    );
}

// Modal component.
const Modal = ({ 
    isOpen, 
    onClose, 
    title, 
    width, 
    height, 
    marginTop,
    children, 
    closeButton }) => {

    if (!isOpen) return null;

    const closeBtn = closeButton || <DefaultCloseButton />
    
    return (
      <Backdrop>
        <ModalContainer width={width} height={height} marginTop={marginTop}>
            <Header>
                <Title>{title}</Title>
                <span onClick={onClose}>
                    {closeBtn}
                </span>
            </Header>
            <Body>
              {children}
            </Body>
        </ModalContainer>
      </Backdrop>
    );
}

export default Modal;