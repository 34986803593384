import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import Home from "./pages/home";
import Login from "./pages/login";
import ControlPanel from "./components/controlPanel/controlPanel";
import Profile from "./components/profile/profile";
import NewPassword from "./components/auth/newPassword";
import Admin from "./components/admin/admin";
import EmptyPage from "./components/empty";
import Logout from "./components/auth/Logout";
import Private from "./pages/private";
import Firma from "./pages/firma";
import Details from "./pages/detail";
import useAuthStore from "./store/useAuthUserStore";
import Contact from "./pages/contact";

const AppRoutes = ({ token }) => {
  const { getUser } = useAuthStore();
  const user = getUser();
 
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/resetpassword/:token" element={<NewPassword />} />
        <Route element={<ProtectedRoute token={token} />}>
          <Route path="/profile" element={<ControlPanel user={user} />}>
            <Route path=":user" element={<Profile user={user} />} />
            <Route path="admin" element={user?.isAdmin ? <Admin /> : <EmptyPage />} />
            <Route path="construction" element={<EmptyPage />} />
          </Route>
        </Route>
        <Route path="/login" element={token ? <Navigate to={`/`}/> : <Login />} />
        <Route path="/logout" element={ <Logout />} />
        <Route path="/" element={<Home user={user} />}>
            <Route path="private" element={<Private />} />
            <Route path="firma" element={<Firma />} />
            <Route path="contact" element={<Contact />} />
            <Route path=":id" element={<Details />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const ProtectedRoute = ({ token }) => {
  if (!token) {
    // Redirect to login page if user is not authenticated
    return <Navigate to="/login" />;
  }
  // Render the children components if user is authenticated
  return <Outlet />;
};

export default AppRoutes;
