import { ToastProvider } from "./toastProvider";
import Toast from "./components/toast";
import useApartmentStore from "./store/useApartmentStore";
import useAuthStore from "./store/useAuthUserStore";
import { getApartments } from "./api/httpServices";
import AppRoutes from "./routes";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { getUserFromToken } from "./helpers/jwt-token";
import { getUser } from "./api/userService";

function App() {
  const { setApartments } = useApartmentStore();
  const { setUser } = useAuthStore();
  const { data, error, isLoading, isFetched } = useQuery({
    queryKey: ["GET_STATE"],
    queryFn: getApartments,
  });

  const { data: user} = useQuery({
    queryKey: ["GET_USER"],
    queryFn: getUser,
  });

  useEffect(()=> {
    if(data) setApartments(data);
    if(user) setUser(user);
  }, [data, user]);

  return (
    <>
      <ToastProvider>
        <AppRoutes token={getUserFromToken()}/>
        <Toast />
      </ToastProvider>
    </>
  );
}

export default App;
