import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

// ------------ Style -----------//
export const TextArea = styled.textarea`
    border-radius: 7px;
    border: 1px solid var(--primary-border);
    width: ${({ width }) => width? width : "100%"};
    background-color: ${({ background }) =>
    background ? `var(${background})` : "transparent"};
    resize: none;
    padding: 10px;
    &:focus{
     outline: none;
    }

`;
const Container = styled.div`
  overflow: scroll;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  min-height: ${({ minheight }) => minheight || "36px"};
  padding: 5px 12px;
  max-height: 35rem;
  border-radius: 7px;
  border: 1px solid var(--primary-border);
  background-color: ${({ background }) =>
    background ? `var(${background})` : "transparent"};
  align-items: center;
  cursor: text;
`;
const InputWrapper = styled.span`
  height: 100%;
  max-width: 100%;
  flex-grow: 1;
  flex-basis: auto;
  background-color: transparent;
`;
const Input = styled.div`
  min-height: ${({ minheight }) => minheight || "36px"};
  max-height: ${({ minheight }) => (minheight ? "35rem" : "15rem")};
  margin: auto 0px;
  background-color: transparent;
  line-height: 125%;
  font-size: 16px;
  &:focus {
    outline: none;
  }
  &:empty::before {
    content: ${({ label }) => (label ? `"${label}"` : "Write a comment")};
    color: var(--placeholder-text);
    font-weight: 600;
    text-shadow: var(--secondary-text);
    font-size: 14px;
  }
`;
const IconWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
`;

// -------------- Component ---------------//
const CustomTextarea = (props) => {
  const inputRef = useRef(null);
  const fileRef = useRef(null);
  const [isBlur, setBlur] = useState(true);

  useEffect(() => {
    inputRef && inputRef.current.focus();
  }, []);

  const onchange = (e) => {
    e.currentTarget = { name: props.name, value: e.target.innerText };
    props.handleInput(e);
  };

  useEffect(() => {
    if (inputRef.current && !props.value) {
      inputRef.current.innerText = "";
    }
  }, [props.value]);

  return (
    <Container background={props.background}>
      <InputWrapper>
        <Input
          dir="auto"
          role={"textbox"}
          ref={inputRef}
          contentEditable={true}
          onInput={onchange}
          innerText={props.value}
          onFocus={() => setBlur(false)}
          onBlur={() => setBlur(true)}
          label={props.label}
          minheight={props.minheight}
        />
        <IconWrapper>{/* any icon goes here */}</IconWrapper>
      </InputWrapper>
    </Container>
  );
};

export default CustomTextarea;
