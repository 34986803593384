import APIclient from "./http";

const authService = new APIclient('user');

export const createUser = async (userData) => {
    const response = await authService.post(userData, 'signup', { withCredentials: true });
    return response;
  };

export const updateUser = async (userData) => {
    const response = await authService.updateFormData(userData, "", { withCredentials: true });
    return response;
  };

export const getUser = async () => {
    const response = await authService.get('', { withCredentials: true });
    return response;
  };


export const authenticate = async(credentials) => {
    const response = await authService.post(credentials, 'login', { withCredentials: true });
    return response;
};

export const unAuthenticate = async() => {
  const response = await authService.get('logout', { withCredentials: true });
  return response;
};

export const activateAcount = async(credentials) => {
  const response = await authService.post(credentials, 'confirmacount', { withCredentials: true });
  return response;
};

export const forgotPassword = async(data) => {
  const response = await authService.post(data, 'forgotpassword');
  return response;
};

export const resetPassword = async(data) => {
  const { password, token } = data;
  const response = await authService.post({password}, `resetpassword/${token}`);
  return response;
};

export const contact = async(data) => {
  const response = await authService.post(data, 'contact');
  return response;
};