import { useState } from "react";
import styled from "styled-components";
import Input from "../Common/Input";
import SlideButton, { ButtonWithSpinner, SimpleButton } from "../Common/buttons";
import { authenticate } from "../../api/userService";
import { useMutation } from "@tanstack/react-query";
import ForgottenPassword from "./forgottenPassword";
import { ButtonText, Caption, Paragraph } from "../Common/typography";
import { Splitter } from "../Common/common";
import { FadeIn } from "../Common/animations";
import { useToast } from "../../toastProvider";
import { useNavigate } from "react-router-dom";

const FormContainer = styled.div`
  width: "100%";
  padding: "1rem";
  animation: ${FadeIn} 0.5s;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 11.8rem;
  padding-left: 1rem;
 padding-top: 1rem;
 display: flex;
 flex-direction: column; !important
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 1rem;
`;

const ForgotPassword = styled.p`
  align-self: flex-end;
  color: var(--color-pink);
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  margin-top: 0.5rem;
  margin-bottom: 0;
  &:hover {
    filter: brightness(85%);
  }
`;

const CheckContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  gap: 8px;
`;

const Logon = ({ togglerRegister }) => {
  const { addToast } = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const login = useMutation({
    mutationFn: authenticate,
    mutationKey: ["LOGIN"],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(!isOpen);

  const handleChange = (e) => {
    const { name, value } = e.currentTarget;
    name === "email" ? setEmail(value) : setPassword(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    login.mutate(
      { email, password },
      {
        onError: (error) => addToast(error?.message, "warning"),
        onSuccess: (user) => {
          if(user) window.location.href = `/profile/${user.name}`;
        },
        onSettled: () => setIsLoading(false),
      }
    );
  };

  return (
    <>
      <FormContainer>
        <Form onSubmit={handleSubmit}>
          <Input
            label="Email address"
            name="email"
            type="email"
            onchange={handleChange}
            value={email}
            border="--primary-border"
            background="--transparent"
            width="100%"
          />
          <ForgotPassword onClick={onClose}>Forgotten Password?</ForgotPassword>
          <Input
            label="Password"
            name="password"
            type="password"
            value={password}
            onchange={handleChange}
            border="--primary-border"
            background="--transparent"
            width="100%"
          />
          <CheckContainer>
            <SlideButton label="Husk mig" state={true}/>
          </CheckContainer>

          <ButtonWithSpinner
            type="submit"
            loading={isLoading}
            color="--color-white"
            hoverColor="--color-white"
            bakcground="--color-pink"
            width="100%"
            hoverBackground="--color-pink9"
            padding="9px"
          >
            {!isLoading && <ButtonText color="--color-white">Login</ButtonText>}
          </ButtonWithSpinner>
        </Form>

        <Wrapper>
          <Splitter>
            <span>Eller</span>
          </Splitter>
          <div style={{ display: "flex", gap: "8px" }}>
            <Caption
              style={{ fontWeight: 500, fontSize: 13, alignSelf: "start" }}
            >
              Ny bruger?
            </Caption>
            <SimpleButton
              padding="0"
              fontSize="14px"
              color="--color-blue"
              hoverColor="--color-blue9"
              onClick={togglerRegister}
            >
              Opret et konto
            </SimpleButton>
          </div>
          <Caption style={{ alignSelf: "center", marginTop: "auto" }}>
            BinarySofts 2024 All rights reserved
          </Caption>
        </Wrapper>
      </FormContainer>

      <ForgottenPassword isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Logon;
