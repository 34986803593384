import { useState } from "react";
import styled from "styled-components";
import { H2 } from "../Common/typography";
import { addApartment } from "../../api/httpServices";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "../../toastProvider";
import FileManager from "./fileManager";
import Form from "./form";
import { SubmitButton } from "./styles";
import useApartmentStore from "../../store/useApartmentStore";

const Container = styled.div`
  overflow: auto;
  padding-top: 3rem;
`;
export default function AddApartment() {
  const { setApartment } = useApartmentStore();
  const { addToast } = useToast();
  const [data, setData] = useState({
    isLoading: false,
    isPrivate: false,
    size: {},
    address: {},
    images: [],
  });

  const newApartment = useMutation({
    mutationFn: addApartment,
    mutationKey: ["ADD_APARTMENT"],
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    setData((pre) => ({ ...pre, isLoading: true }));
    newApartment.mutate(data, {
      onError: (error) => addToast(error.response?.data?.message, "warning"),
      onSuccess: (data) => {
        setApartment(data);
        setData({
          isLoading: false,
          isPrivate: false,
          size: {},
          address: {},
          images: [],
        });
      },
      onSettled: () => setData((pre) => ({ ...pre, isLoading: false })),
    });
  };

  return (
    <Container>
      <H2 style={{ marginBottom: "1.5rem" }}>Tilföj lejemål</H2>
      <FileManager data={data} setData={setData} />
      <Form data={data} setData={setData} handleSubmit={handleSubmit}>
        <SubmitButton loading={data.isLoading} type="submit">Tilfoj</SubmitButton>
      </Form>
    </Container>
  );
}
