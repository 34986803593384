import axios from "axios";
import { API_URL } from "../constants";

const axiosInstance = axios.create({
  baseURL: API_URL//process.env.REACT_APP_API_URL,

});

class APIclient {
  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  setEndpoint = (suffix) => `${this.endpoint}/${suffix}`;

  formData = (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === "images" && Array.isArray(value)) {
        value.forEach((imageObj, index) => {
          if(typeof imageObj === 'object')
            formData.append('files', imageObj.file);
          else
            formData.append("images", imageObj);
        });
      } else if(key === "size" || key === "address"){
        Object.entries(value).forEach(([subKey, subValue]) => {
          if(key === "size")
            formData.append(`size.${subKey}`, subValue);
          else 
            formData.append(`address.${subKey}`, subValue);
          });
      } else {
        if(key !== "isLoading")
          formData.append(key, value);
      }
    });
  
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };
  
    return { formData, config };
  };
  

  get = (subUrl="", options={}) => {
    return axiosInstance.get(`${this.endpoint}/${subUrl}`, options).then((res) => res.data);
  };

  getById = (id, options={}) => {
    return axiosInstance.get(`${this.endpoint}/${id}`, options).then((res) => res.data);
  };

  post = (data, suffix, options) => {
    const endpoint = suffix ? this.setEndpoint(suffix) : this.endpoint;
    return axiosInstance
      .post(endpoint, data, options && options)
      .then((res) => res.data);
  };

  postFormData = (data, suffix, options) => {
    const endpoint = suffix ? this.setEndpoint(suffix) : this.endpoint;
    const { formData, config } = this.formData(data);

    return axiosInstance
      .post(endpoint, formData, {...config, ...options})
      .then((res) => res.data);
  };

  updateFormData = (data, suffix, options) => {
    const endpoint = suffix ? this.setEndpoint(suffix) : this.endpoint;
    const { formData, config } = this.formData(data);
    
    return axiosInstance
      .put(endpoint, formData, {...config, ...options})
      .then((res) => res.data);
  };

  updateById = (id) => {
    return axiosInstance
      .patch(`${this.endpoint}/${id}`)
      .then((res) => res.data);
  };

  delete = (id, options) => {
    return axiosInstance
      .delete(`${this.endpoint}/${id}`, options)
      .then((res) => res.data);
  };
}

export default APIclient;
