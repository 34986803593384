import styled from "styled-components";
import useApartmentStore from "../../store/useApartmentStore";
import Gallary from "../gallery/gallary";

const Container = styled.div`
  display: flex;
  gap: 20px;
`;

export default function SliderGallary({ isRetail }) {
  const { apartments } = useApartmentStore();
  return (
    <Container>
      {apartments && apartments.map((apartment) => {
        // Render public apartments if isRetail is true, otherwise render private apartments
        if (isRetail && !apartment.isPrivate) {
          return <Gallary key={apartment.id} apartment={apartment} />;
        } else if (!isRetail && apartment.isPrivate) {
          return <Gallary key={apartment.id} apartment={apartment} />;
        }
        return null; // Ensure we return null for cases that don't match the condition
      })}
    </Container>
  );
}
