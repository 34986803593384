import { create } from "zustand";

const useApartmentStore = create((set, get) => ({
  apartments: [],

  setApartments: (apartments) => set({ apartments }),

  getApartment: (id) => {
    const { apartments } = get();
    return apartments.find((apartment) => apartment._id === id);
  },

  
  setApartment: (apartment) => {
    set((store) => {
      const preData = [...store.apartments];
      preData.push(apartment);
      return { apartments: preData };
    });
  },

  upApartment: (apartment) => {
    set((store) => {
      const index = store.apartments.findIndex(
        (property) => property._id === apartment._id
      );
      if (index !== -1) {
        const preData = [...store.apartments];
        preData[index] = apartment;
        return { apartments: preData };
      }
      return store;
    });
  },
  rmApartment: (apartment) => {
    set((store) => {
      const index = store.apartments.findIndex(
        (property) => property._id === apartment._id
      );
      if (index !== -1) {
        const preData = [...store.apartments];
        preData.splice(index, 1); // Removes the element at the specified index
        return { apartments: preData };
      }
      return store;
    });
  },

}));

export default useApartmentStore;
