// Carousel.js
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { breakpoints } from "../constants";

const CarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
`;

const CardsWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  padding: 10px 0;
  padding-left: calc(2 * 320px); /* Ensure it starts showing the first card */

  @media (min-width: ${breakpoints.md}) {
   padding-left: calc(2 * 720px); 
  }
  @media (min-width: ${breakpoints.lg}) {
   padding-left: calc(2 * 920px); 
  }
  &:hover {
    animation-play-state: paused;
  }
`;

const Card = styled.div`
  width: 300px; /* 3 times the original width */
  height: 400px; /* 2 times the original height */
  margin: 0 10px;
  box-shadow: var(--cart-boxshadow); /* 0 4px 8px rgba(0, 0, 0, 0.2); */
  color: #333;
  border-radius: 10px;
  overflow: hidden;
  @media (min-width: ${breakpoints.sm}) {
    height: 500px;
    overflow: auto;
  }
  @media (min-width: ${breakpoints.md}) {
    width: 700px;
  }
  @media (min-width: ${breakpoints.lg}) {
    width: 900px;
  }
`;

const Carousel = ({ card1, card2, card3 }) => {
  const [sequenceIndex, setSequenceIndex] = useState(0);
  const cardsWrapperRef = useRef(null);
  const intervalRef = useRef(null);

  const sequence = [0, 1, 2, 1, 0]; // Indices of the sequence: 0 -> 1 -> 2 -> 1 -> 0

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      setSequenceIndex((prevIndex) => (prevIndex + 1) % sequence.length);
    }, 3000);
  };

  const clearExistingInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    startInterval();
    return clearExistingInterval;
  }, []);

  useEffect(() => {
    let cardWidth = 320; // Default card width
    if (window.matchMedia(`(min-width: ${breakpoints.md})`).matches) {
      cardWidth = 720; // Adjust card width for desktop
    }
    if (window.matchMedia(`(min-width: ${breakpoints.lg})`).matches) {
      cardWidth = 920; // Adjust card width for desktop
    }

    if (cardsWrapperRef.current) {
      const currentCardIndex = sequence[sequenceIndex];
      cardsWrapperRef.current.style.transform = `translateX(${
        -currentCardIndex * cardWidth
      }px)`; // Adjust for new card width + margin
    }
  }, [sequenceIndex]);

  const handleMouseEnter = () => clearExistingInterval();
  const handleMouseLeave = () => startInterval();

  return (
    <CarouselContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CardsWrapper ref={cardsWrapperRef}>
        <Card key={1}>{card1}</Card>
        <Card key={2}>{card2}</Card>
        <Card key={3}>{card3}</Card>
      </CardsWrapper>
    </CarouselContainer>
  );
};

export default Carousel;
