import { useEffect, useState } from "react";
import styled from 'styled-components';
import Modal from "../Common/modal";
import Input from "../Common/Input";
import { ButtonWithSpinner } from "../Common/buttons";
import { ButtonText, Paragraph } from "../Common/typography";
import { forgotPassword } from "../../api/userService";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "../../toastProvider";
import { PASSWORD_RESET_MESSAGE, SUCCESS_RESET_MESSAGE } from "../../constants";

const Form = styled.form`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const ForgottenPassword = ({ isOpen, onClose }) => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(null);
    const { addToast } = useToast();

    const resetPassword = useMutation({
        mutationFn: forgotPassword,
        mutationKey: ["PASS_RESET"],
      });

    const handleChange = (e) => setEmail(e.currentTarget.value);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        resetPassword.mutate({email: email}, {
            onError: (error) => addToast(error?.message, "warning"),
            onSuccess: () => addToast(SUCCESS_RESET_MESSAGE, 'info'),
            onSettled: () => {
                setIsLoading(false);
                onClose();
            },
          });
    };

    return (
        <Modal 
            isOpen={isOpen} 
            onClose={onClose} 
            title={'Nullstil Password'}
            >

            <hr/>
            <Paragraph>{PASSWORD_RESET_MESSAGE}</Paragraph>
            <Form onSubmit={handleSubmit}>
                <Input
                    required={true}
                    label="Email address for your account"
                    name="email"
                    type="email"
                    onchange={handleChange}
                    value={email}
                    />
                <ButtonWithSpinner 
                type="submit" 
                width={"12rem"}
                radius="7px" 
                bakcground="--color-pink"
                hoverBackground="--color-pink9"
                style={{alignSelf: "center"}}
                padding={isLoading ? "1.5rem":"9px"}
                disabled={isLoading}
                loading={isLoading}
              >
                {!isLoading && <ButtonText color="--color-white">Nullstil</ButtonText>}
                </ButtonWithSpinner>
            </Form>
        </Modal>
    );
}

export default ForgottenPassword;