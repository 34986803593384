import { create } from 'zustand';

const useAuthStore = create((set, get) => ({
    user: {},
    setUser: (user) => set({ user }),

    getUser: () => {
        const { user } = get();
        return user;
      },
    
}));

export default useAuthStore;