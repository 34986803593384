import styled from "styled-components";
import useAuthStore from "../store/useAuthUserStore";
import { AppContainer } from "../layout/layout";
import { CallOut } from "../components/Common/common";
import talking from "../assets/talking.jpeg";
import {
  H5,
  Link,
  StrongTitle,
  Text,
} from "../components/Common/typography";
import Carousel from "../components/couresel";
import officeImg from "../assets/office.jpeg";
import { GoDotFill } from "react-icons/go";
import togetherImg from "../assets/together.png";
import virtualImg from "../assets/virtual1.png";
import SliderGallary from "../components/sliderGallary/sliderGallary";
import { SimpleButton } from "../components/Common/buttons";
import { WideCart } from "../components/Common/common";
import { breakpoints } from "../constants";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";


const Containers = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  @media (min-width: ${breakpoints.sm}) {
    padding: 0 2rem;
  }

  @media (min-width: ${breakpoints.xl}) {
    padding: 0 8rem;
  }
`;

const CalloutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  gap: 10px;
  margin-top: 1rem;
  @media (min-width: ${breakpoints.lg}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 7rem;
    gap: 5rem;
  }
`;

const Cards = styled.div`
  display: flex;
  height: 100%;
  align-items: start;
  padding: 20px;
  background-color: ${({ background }) =>
    background ? `var(${background})` : "var(--color-white)"};
  justify-content: space-evenly;
  img {
    display: none;
    height: 100%;
    width: 300px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    fit-object: cover;
  }
  div {
    flex-grow: 1;
  }
  @media (min-width: ${breakpoints.sm}) {
    img {display: none;}
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 30px;
    img {display: none;}
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 15px;
    gap: 20px;
    img {
      display: block;
      height: 100%;
      width: 350px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      fit-object: cover;
    }
    div {
      display: flex;
      flex-direction: column;
      gap: 10px;
    } 
  
  }
  @media (min-width: ${breakpoints.xl}) {
      img {
      height: 100%;
      width: 400px;
    }
}
`;
const SlideWrapper = styled.div`
  display: flex;
  padding: 1.5rem 2rem;
  @media (min-width: ${breakpoints.lg}) {
    padding: 2.5rem 8rem;
  }
`;

const Firma = ({ steps }) => {
  const { user } = useAuthStore();
  const { hide } = useOutletContext();
  useEffect(()=> {
      if (hide) hide(false);
  }, [hide]);

  return (
    <AppContainer>
      <SlideWrapper>
        <SliderGallary isRetail={true} />
      </SlideWrapper>
      <Carousel
        card1={
          <Cards background="--color-blue-dark">
            <img src={officeImg} />
            <div>
              <StrongTitle fontSize="26px">Kontor i Rødby</StrongTitle>
              <Text>
                Få dit eget private kontor hos Estate Build i Rødby og skab det
                perfekte arbejdsmiljø til din virksomhed. Vi står for alt, lige
                fra møbler til wi-fi, så du kan koncentrere dig om at vækste din
                virksomhed. Vi kan hjælpe dig med følgende:
              </Text>
              <article>
                <H5>
                  <GoDotFill color="var(--color-blue)" /> Privatkontorer op til
                  10m^2.
                </H5>
                <H5>
                  <GoDotFill color="var(--color-blue)" /> Dagkontorer for mere
                  fleksibilitet.
                </H5>
                <H5>
                  <GoDotFill color="var(--color-blue)" /> Login bruger så du har
                  alle dine dokumenter
                </H5>
              </article>
              <article>
   
                  <Text>Glemt et sted og en direkte kontaktperson du kan kontakte.</Text>
               
                <H5>
                  <GoDotFill color="var(--color-green)" /> Adgang til mødelokale
                </H5>
                <H5>
                  <GoDotFill color="var(--color-green)" /> Oplad din bil og
                  parkere gratis op til 3 timer
                </H5>
              </article>
            </div>
          </Cards>
        }
        card2={
          <Cards background="--color-pink-light" style={{ gap: "0px" }}>
            <img src={virtualImg} style={{ height: "auto" }} />
            <div>
              <StrongTitle>Virtuel Kontor i Rødby</StrongTitle>
              <Text fontSize="15px">
                Hvis du driver din virksomhed fra din private adresse, kan det
                være en god idé at bruge et virtuelt kontor. Med et virtuelt
                kontor kan du beskytte din private adresse samtidig med, at du
                forbedrer din virksomheds troværdighed og professionelle image
                over for kunder og samarbejdspartnere. Vi kan hjælpe dig med
                følgende:
              </Text>
              <article>
                <H5>
                  <GoDotFill color="var(--color-green)" /> Post og pakke
                  håndtering
                </H5>
                <H5>
                  <GoDotFill color="var(--color-green)" /> Adgang til mødelokale
                </H5>
                <H5>
                  <GoDotFill color="var(--color-green)" /> En professionel
                  forretningsadresse på en eftertragtet beliggenhed
                </H5>
                <H5 color="--primary-text">
                  Login bruger så du har alle dine dokumenter gemt et sted fra
                  kontrakter til faktura og en direkte kontaktperson du kan
                  kontakte, samt vil din post blive scannet ind og uploaded til
                  din login så du altid har dem ved hånden
                </H5>
              </article>
            </div>
          </Cards>
        }
        card3={
          <Cards background="--color-green-light" style={{ gap: "5px" }}>
            <img src={togetherImg} style={{ height: "auto" }} />
            <div>
              <StrongTitle fontSize="28px">Fællesskabskontorer i Rødby</StrongTitle>
              <Text fontSize="15px">
                Samarbejd med erhvervsfolk fra forskellige brancher i vores
                pulserende fællesskabsomgivelser i Rødby. Vi tilbyder ikke kun
                netværksarrangementer, men også fleksible arbejdsområder, hvor
                samarbejde og vækst kan trives. Vælg at reservere dit eget
                skrivebord på et delt kontor eller vælg din faste plads. Vi kan
                hjælpe dig med følgende:
              </Text>
              <article>
                <H5>
                  <GoDotFill color="var(--color-blue)" /> Faste skriveborde med
                  adgang 24/7 og Adresse registering
                </H5>
                <H5>
                  <GoDotFill color="var(--color-blue)" /> Dagsskriveborde med
                  adgang i mellem 06:00 - 18:00
                </H5>
                <H5>
                  <GoDotFill color="var(--color-blue)" /> Login bruger så du har
                  alle dine dokumenter gemt et sted fra kontrakter til faktura
                  og en direkte kontaktperson du kan kontakte
                </H5>
                <H5>
                  <GoDotFill color="var(--color-blue)" /> Adgang til mødelokale
                </H5>
                <H5>
                  <GoDotFill color="var(--color-blue)" /> Oplad din bil og
                  parkere gratis op til 3 timer
                </H5>
              </article>
            </div>
          </Cards>
        }
      />
      <Containers>
        <WideCart>
          <img src={talking} />
          <div>
            <StrongTitle>Kontor i Rødby</StrongTitle>
            <Text>
              Få dit eget private kontor hos Estate Build i Rødby og skab det
              perfekte arbejdsmiljø til din virksomhed. Vi står for alt, lige
              fra møbler til wi-fi, så du kan koncentrere dig om at vækste din
              virksomhed. Vi kan hjælpe dig med følgende: * Privatkontorer op
              til 10m^2. * Dagkontorer for mere fleksibilitet. * Login bruger så
              du har alle dine dokumenter gemt et sted og en direkte
              kontaktperson du kan kontakte. * Adgang til mødelokale * Oplad din
              bil og parkere gratis op til 3 timer.
            </Text>

            <Link style={{marginTop: "20px"}} href="mailto:kontakt@estatebuild.dk">
              <SimpleButton
                hoverBackground="--color-pink"
                width="8rem"
                padding="10px 1rem"
                color="--color-white"
                bakcground="--color-pink"
                hoverColor="--color-white"
              >
                Kontakt
              </SimpleButton>
            </Link>
          </div>
        </WideCart>
        <CalloutContainer>
          <CallOut titleColor="--color-green" borderColor="--color-green">
            <h4>VISION</h4>
            <p>
              Vi ønsker at Estate Builds boliger skal være tiltalende for hver
              enkelt beboer. Vi stræber efter at være synlige og relevante i
              lokalsamfundet og aktivt bidrage til den lokale byudvikling.
            </p>
          </CallOut>
          <CallOut>
            <h4>MISSION</h4>
            <p>
              Vi ønsker at Estate Builds boliger skal være tiltalende for hver
              enkelt beboer. Vi stræber efter at være synlige og relevante i
              lokalsamfundet og aktivt bidrage til den lokale byudvikling.
            </p>
          </CallOut>
        </CalloutContainer>
      </Containers>
    </AppContainer>
  );
};

export default Firma;
