import React, { useState } from "react";
import styled from "styled-components";
import PanelNav from "./nav";
import { Outlet } from "react-router-dom";
import { breakpoints } from "../../constants";
import useBreakpoint from "../../hooks/useBreakpoints";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
`;
const InnerContainer = styled.div`
  display: flex;
  height: 100%;
`;
const NavContainer = styled.div`
  postion: relative;
  width: 5rem;
  height: 100%;
  transition: all .3 s;
  @media (min-width: ${breakpoints.md}) {
    width: 20rem;
  }
`;
const Panel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => (padding ? padding : "0px")};
  padding-top: 20px;
`;


const ControlPanel = ({ user }) => {
  
  return (
    <Container>

      <InnerContainer>
        <NavContainer>
          <PanelNav user={user} />
        </NavContainer>
        <Panel>
          <Outlet />
        </Panel>
      </InnerContainer>
    </Container>
  );
};

export default ControlPanel;
