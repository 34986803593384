import { useRef } from "react";
import styled from "styled-components";
import TextConcut, { H4, Text } from "../Common/typography";
import { RoundButton, SimpleButton } from "../Common/buttons";
import { RiAttachment2 } from "react-icons/ri";
import { BsFileImage } from "react-icons/bs";
import { MdOutlineClose } from "react-icons/md";
import { breakpoints } from "../../constants";

const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 5px;
  display: flex;
  gap: 7px;
  box-shadow: var(--cart-boxshadow);
  border-radius: 12px;
  background-color: var(--color-white);
  transition: height 0.9s ease-out;
  margin-bottom: 1rem;
`;
const UploadedContainer = styled.div`
  border-top: 1px solid var(--primary-border);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  @media (min-width: ${breakpoints.md}) {
    
  }
`;
const File = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  gap: 10px;
  border-radius: 8px;
  width: 100%;
  p{
    font-size: 14.5px;
    cursor: default;
    @media (min-width: ${breakpoints.md}) {
      font-size: 15px;
    }
  }
  color: var(--secondary-text);
  &:hover {
    background-color: var(--transparent05);
    color: var(--primary-text);
  }

`;
const Header = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
const InputButton = styled(SimpleButton)`
  border-radius: 100%;
  background-color: var(--color-pink);
  color: var(--color-white);
  font-size: 22px;
  padding: 10px;
  &:hover {
    background-color: var(--color-blue);
    color: var(--color-white);
  }
`;

const bytesToMB = (bytes) => (bytes / (1024 * 1024)).toFixed(2);

export default function FileManager({ data, setData }) {
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const imgArr = [...data.images];
    imgArr.push({
      file: e.target.files[0],
      uri: URL.createObjectURL(e.target.files[0]),
    });

    setData((prev) => ({ ...prev, images: imgArr }));
  };

  const handleRemoveFile = (file) => {
    const imgArr = [...data.images];
    const index = imgArr.indexOf(file);
    imgArr.splice(index, 1);
    setData((prev) => ({ ...prev, images: imgArr }));
  };

  return (
    <FileContainer>
      <Header>
        <InputButton onClick={(e) => fileInputRef.current.click()}>
          <RiAttachment2 />
        </InputButton>
        <H4>Vedhæftede filer</H4>
      </Header>
      <input
        ref={fileInputRef}
        onChange={handleFileChange}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
      />
      {data.images.length > 0 && (
        <UploadedContainer>
          {data.images?.map((image) => (
            <File key={image.file?.size}>
              <BsFileImage size={19} color="var(--color-green)" />
              <Text fontSize="16px">
                <TextConcut content={image.file?.name} />{" "}
                {/* Use TextConcut as a component */}
              </Text>
              <Text fontSize="15px" style={{ marginLeft: "auto" }}>
                {bytesToMB(image.file?.size) + " MB"}
              </Text>
              <RoundButton size="1rem" onClick={() => handleRemoveFile(image)}>
                <MdOutlineClose size={19} color="var(--primary-text)"/>
              </RoundButton>
            </File>
          ))}
        </UploadedContainer>
      )}
    </FileContainer>
  );
}
