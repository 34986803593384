import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import styled from "styled-components";
import { SimpleButton } from "./buttons";

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownContent = styled.div`
  border-radius: 10px;
  background-color: var(--color-white);
  width: ${({ width }) => (width ? width : "calc(100% - 5px)")};
  position: absolute;
  transform: translateX(${({ translate }) => translate ? translate : "0"});
  top: ${({ top }) => top ? top : "calc(100% + 10px)"};
  left: ${({ left }) => (left ? left : "5px")};
  right: ${({ right }) => (right ? right : "5px")};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DropdownButton = styled.span`

`;

export const Item = styled(SimpleButton)`
  color: var(--secondary-text);
  font-size: 15px;
  gap: 7px;
  padding: 6px 8px;
  border-radius: 7px;
  width: 100%;
  justify-content: flex-start;
`;

const Dropdown = ({ dropdownBtn, children, ...props }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close the dropdown when clicked outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  useImperativeHandle(ref, () => ({ closeDropdown }));

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton
        onClick={toggleDropdown}
        width={props.width}
        left={props.left}
        right={props.right}
        top={props.top}
        translate={props.translate}
      >
        {dropdownBtn}
      </DropdownButton>

      {isOpen && <DropdownContent {...props}>{children}</DropdownContent>}
    </DropdownContainer>
  );
};

export default forwardRef(Dropdown);
