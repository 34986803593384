import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { API_URL } from "../../constants";
import { IoMdArrowForward, IoMdArrowBack } from "react-icons/io";
import { SlideRight } from "../Common/animations";
import { H4, H5, H6, Text } from "../Common/typography";
import { PLACE_TYPE, STATUS, FACILITY } from "../../constants";
import { BsFillHouseFill } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";
import { MdBedroomParent } from "react-icons/md";
import { TbChartAreaLineFilled } from "react-icons/tb";
import SlideShow from "../slideShow/slideShow";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  width: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: var(--cart-boxshadow);
  background-color: var(--color-white);
  cursor: pointer;
`;
const ImageContainer = styled.div`
  position: relative;
  &:hover .slide {
    opacity: 1;
  }
`;
const Slide = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(50% + 22px);
  display: flex;
  justify-content: space-between;
  padding: 5px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  padding-top: 0;
  gap: 15px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
`;
const Info = styled.div`
  display: flex;
  gap: 15px;
`;
const Span = styled.span`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export default function Gallary({ apartment }) {
  const { images, address, size } = apartment;
  const navigate = useNavigate();
  return (
    <Container>
      <ImageContainer>
       <SlideShow urls={images} width="100%" height="200px"/>
      </ImageContainer>
      <Footer onClick={()=> navigate(`/${apartment._id}`)}>
        <Header>
          <Text fontSize="14px">
            <BsFillHouseFill size={13} /> {PLACE_TYPE[apartment.type]}
          </Text>
          <Span>
            <GoDotFill color="var(--color-blue)" fontSize={14} />
            <Text fontSize="14px">{STATUS[apartment.status]}</Text>
          </Span>
        </Header>
        <Body>
          <H4>{address.street}</H4>
          <div style={{ display: "flex" }}>
            <H4>{address.city}</H4>
            <H4>{address.zip}</H4>
          </div>
        </Body>
        <Info>
          <Text fontSize="14px">{apartment.price} kr.</Text>
          <Span>
            <TbChartAreaLineFilled fontSize={14} />
            <Text fontSize="13px">{size.squareFeet}m²</Text>
          </Span>
          <Span>
            <MdBedroomParent fontSize={13} />
            <Text fontSize="13px">{size.numberOfRooms} rum</Text>
          </Span>
        </Info>
      </Footer>
    </Container>
  );
}
