import { useEffect, useState } from "react";
import styled from "styled-components";
import AddApartment from "./addApartment";
import Apartments from "./apartments";
import UpdateApartment from "./updateApartment";
import useAuthStore from "../../store/useAuthUserStore";
import { breakpoints } from "../../constants";
import { MdArrowForwardIos, MdArrowBackIosNew } from "react-icons/md";
import useBreakpoint from "../../hooks/useBreakpoints";
import { RoundButton } from "../Common/buttons";

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  overflow: auto;
`;
const LeftPanel = styled.div`
  padding: 5px;
  overflow: scroll;
  width: 0px;
  transition: all 0.1s ease-in-out;
  @media (min-width: ${breakpoints.xl}) {
    width: 50%;
    padding-left: 2rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;
const RightPanel = styled.div`
  width: 100%;
  overflow: scroll;
  padding-right: 1rem;
  padding-left: 1rem;
  @media (min-width: ${breakpoints.xl}) {
    width: 50%;
    padding-top: 2rem;
    padding-left: 0;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-left: 0px;
    margin-left: -30px;
  }
  @media (min-width: ${breakpoints.md}) {
    padding-left: 0px;
    margin-left: -10px;
  }
  @media (min-width: ${breakpoints.sm}) {
    padding-left: 0px;
    margin-left: -20px;
  }
`;
const ButtonWrapper = styled.div`
  position: absolute;
  top: 85%;
  transform: translateY(-50%);
  left: 1.5rem;
  z-index: 99;
  transition: all 0.2s;
  @media (min-width: ${breakpoints.md}) {
    left: 12.5rem;
    top: 50%;
  }
  @media (min-width: ${breakpoints.lg}) {
    left: 14.5rem;
  }
  @media (min-width: ${breakpoints.xl}) {
    display: none;
  }

`;

export default function Admin() {
  const [apartment, setApartment] = useState(null);
  const [adminUser, setAdminUser] = useState(null);
  const { getUser } = useAuthStore();
  const breakpoint = useBreakpoint();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!adminUser) {
      const user = getUser();
      setAdminUser(user);
    }
  }, [adminUser]);

  useEffect(() => {
    if (breakpoint === "xl") setExpanded(false);
  }, [breakpoint]);

  useEffect(() => {
    if (apartment) setExpanded(true);
  }, [apartment]);

  if (adminUser?.isAdmin)
    return (
      <Container>
        <ButtonWrapper
        >
          <RoundButton
            onClick={() => setExpanded(!expanded)}
            color="--color-white"
            background="--color-pink"
            height="45px"
            width="45px"
            fontSize="18px"
            hoverBackground="--color-pink9"
          >
            {expanded ? (
              <MdArrowForwardIos size={18} />
            ) : (
              <MdArrowBackIosNew size={18} />
            )}
          </RoundButton>
        </ButtonWrapper>
        <LeftPanel
          style={{
            width: expanded ? "100%" : breakpoint === "xl" ? "50%" : "0",
          }}
        >
          {apartment ? (
            <UpdateApartment aprt={apartment} addState={setApartment} />
          ) : (
            <AddApartment />
          )}
        </LeftPanel>
        <RightPanel
          style={{
            width: expanded ? "0" : breakpoint === "xl" ? "50%" : "100%",
            opacity: expanded ? "0" : "1",
          }}
        >
          <Apartments addState={setApartment} />
        </RightPanel>
      </Container>
    );

  return <></>;
}
