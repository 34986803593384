import { useCallback, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { API_URL, breakpoints } from "../constants";
import useApartmentStore from "../store/useApartmentStore";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowLeftCircleFill, BsArrowUpCircleFill } from "react-icons/bs";
import { FadeInNoSlide } from "../components/Common/animations";
import { H3, H4, H5, H6, Text } from "../components/Common/typography";
import { Splitter } from "../components/Common/common";
import { FACILITY, PLACE_TYPE, STATUS } from "../constants";
import defaultImage from "../assets/noPhoto-b.png";



const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--web-wash);
  margin-top: -4.5rem;
  padding: 0 5px;
`;

const TopContainer = styled.div`
  display: flex;
  width: 100%;
  height: 700px;
`;

const ImageContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
  }
`;

const LargeImage = styled.div`
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
  @media (min-width: ${breakpoints.md}) {
    height: 600px;
  }
  @media (min-width: ${breakpoints.lg}) {
    height: 700px;
  }
`;
const SlideContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 5px;
  height: 300px;
  overflow: auto;
  @media (min-width: ${breakpoints.lg}) {
      flex-direction: column;
      height: 700px;
  }
`;
const Pointer = styled.span`
  display: inline-block;
  position: absolute;
  border-radius: 100%;
  cursor: pointer;
  top: 50px;
  left: 100px;
  z-index: 9;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
  box-shadow: ${({ error }) => (error ? "0" : "var(--dropdown-boxShadow)")};

  ${SlideContainer}:hover &,
  ${LargeImage}:hover & {
    visibility: visible;
    opacity: 1;
  }

  &:active {
    transform: scale(0.96);
  }
`;
const Image = styled.img`
  width: ${({ error }) => (error ? "50%" : "100%")};
  height: 100%;
  object-fit: cover; /* Ensures the image covers the div */
  position: absolute; /* Ensures the image fills the container */
  z-index: 1;
  top: 0;
  left: ${({ error }) => (error ? "50%" : "0")};
  transform: translateX(${({ error }) => (error ? "-50%" : "0")});
  animation: ${({ isEntering }) =>
    isEntering &&
    css`
      ${FadeInNoSlide} 0.5s ease-in-out;
    `};
`;

const Tombnail = styled.img`
  width: ahto;
  height: 150px;
  width: 220px;
  object-fit: conver;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.03);
  }
  cursor: pointer;
    @media (min-width: ${breakpoints.md}) {
    width: 280px;
    height: 180px;
  }
  @media (min-width: ${breakpoints.lg}) {
    width: 300px;
    height: auto;
  }
`;
const BottomContainer = styled.div`
  margin-top: 2rem;
  position: relative;
  display: flex;
  width: 100%;
  min-height: 20rem;
  justify-content: center;
  margin-top: -100px;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 0px;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin-top: 50px;
  }
`;
const BottomWrapper = styled.div`
  padding: 0;
  display: flex;
  min-height: 20rem;
  background-color: var(--color-pink-light);
  border-radius: 25px;
  @media (min-width: ${breakpoints.lg}) {
    padding: 3rem;
  }
`;
const Cart = styled.div`
  width: 350px;
  padding: 2rem;
  box-shadow: var(--cart-boxshadow);
  background-color: var(--color-pink);
  border-radius: 14px;
  @media (min-width: ${breakpoints.md}) {
    width: 450px;
  }
`;
const InnerCart = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
`;
const InnerFooter = styled.div`
  margin-left: 4rem;
  width: 450px;
  padding: 2rem;
  display: none;
  @media (min-width: ${breakpoints.lg}) {
    display: block;
  }

`;
const ButtonWrapper = styled.div`
  position: absolute;
  z-index: 9;
  left: -50px;
  top: 0px;
`;

export default function Details() {
  const bottomRef = useRef(null);
  const { getApartment } = useApartmentStore();
  const [apartment, setApartment] = useState(null);
  const [position, setPosition] = useState(0);
  const [isEntering, setIsEntering] = useState(false);
  const [isError, setIsError] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const apr = getApartment(id);
    setApartment(apr);
  }, [id]);

  const getIndex = (element) => {
    const index = apartment?.images.indexOf(element);
    setTimeout(() => {
      setIsEntering(true);
      setPosition(index);
    }, 300);
    setIsEntering(false);
  };

  const handleError = (event) => {
    event.target.src = defaultImage; // Set default image URL
    setIsError(true);
  };

  return (
    <Container>
      <TopContainer>
        <ImageContainer>
          <LargeImage isEntering={isEntering}>
            <ButtonWrapper>
              <Pointer onClick={() => navigate(-1)} error={isError}>
                <BsArrowLeftCircleFill size={55} color={isError ? "var(--transparent5)" : "var(--transparent08)"} />
              </Pointer>
            </ButtonWrapper>
            <Image
              error={isError}
              src={API_URL + apartment?.images[position]}
              isEntering={isEntering}
              onError={handleError}
            />
          </LargeImage>
          <SlideContainer ref={bottomRef}>
            {apartment?.images.map((image, index) => (
              <Tombnail
                key={index}
                src={API_URL + image}
                onClick={() => getIndex(image)}
              />
            ))}
          </SlideContainer>
        </ImageContainer>
      </TopContainer>
      <BottomContainer>
        <BottomWrapper>
          <Cart>
            <InnerCart style={{ paddingBottom: "15px" }}>
              <H5 color="--color-white">Status</H5>
              <H5 color="--color-white">{STATUS[apartment?.status]}</H5>
            </InnerCart>
            <H3 color="--color-white">{apartment?.address.street}</H3>
            <H3 color="--color-white">{`${apartment?.address.city} ${apartment?.address.zip}`}</H3>
            <Splitter>
              {" "}
              <Text color="--color-white" fontSize="12px">
                Detalje
              </Text>
            </Splitter>
            <InnerCart>
              <H5 color="--color-white" fontWeight="500">
                Kontantprice
              </H5>
              <H5 color="--color-white">{apartment?.price}</H5>
            </InnerCart>
            <InnerCart>
              <H5 color="--color-white">Ejedom/leje prise</H5>
              <H5 color="--color-white">{apartment?.price}</H5>
            </InnerCart>
            <InnerCart>
              <H5 color="--color-white">Bolige type</H5>
              <H5 color="--color-white">{PLACE_TYPE[apartment?.type]}</H5>
            </InnerCart>
            <InnerCart>
              <H5 color="--color-white">boligareal</H5>
              <H5 color="--color-white">{apartment?.size.squareFeet}</H5>
            </InnerCart>
            <InnerCart>
              <H5 color="--color-white">Grundareal</H5>
              <H5 color="--color-white">{apartment?.size.squareFeetLand}</H5>
            </InnerCart>
            <InnerCart>
              <H5 color="--color-white">Antal rum</H5>
              <H5 color="--color-white">{apartment?.size.numberOfRooms}</H5>
            </InnerCart>
            <InnerCart>
              <H5 color="--color-white">Antal badevearelse</H5>
              <H5 color="--color-white">{apartment?.size.numberOfBathrooms}</H5>
            </InnerCart>
            <InnerCart>
              <H5 color="--color-white">Etage</H5>
              <H5 color="--color-white">{apartment?.size.floor}</H5>
            </InnerCart>
            <InnerCart>
              <H5 color="--color-white">Facilitet</H5>
              <H5 color="--color-white">{FACILITY[apartment?.facility]}</H5>
            </InnerCart>
          </Cart>
          <InnerFooter>
            <h2 color="var(--primary-text)">{apartment?.title}</h2>
            <Text>{apartment?.description}</Text>
          </InnerFooter>
        </BottomWrapper>
      </BottomContainer>
    </Container>
  );
}
