import styled from "styled-components";
import { ButtonWithSpinner } from "../Common/buttons";

export const SubmitButton = styled(ButtonWithSpinner)`
    background-color: var(--color-pink);
    color: var(--color-white);
    &:hover {
        color: var(--color-white);
        background-color: var(--color-green9);
    }
    font-weight: 500;
    width: 60%;
    padding: 0.6rem 0.5rem;
    border-radius: 10px;
    align-self: center;
    margin-top: 10px;
    letter-spacing: 1px;
`;

export const Container = styled.div`
overflow: auto;
padding-top: 3rem;
`;