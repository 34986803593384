import styled from "styled-components";
import { H3 } from "./typography";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";


const LogoContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
  }
`;

export default function Logo() {
  return (
    <Link style={{textDecoration: 'none'}} to="/">
    <LogoContainer>
      <H3 color="--color-pink">EstateBuild</H3>
      <img src={logo} style={{ width: "auto", height: 40 }} />
    </LogoContainer>
    </Link>
  );
}
