export function formatRelativeTime(date) {
  const today = new Date();
  const postDate = new Date(date);
  const diffInMilliseconds = today - postDate;
  const options = {
    numeric: "auto",
  };
  const rtf = new Intl.RelativeTimeFormat("en", options);

  if (diffInMilliseconds >= 86400000) {
    return postDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
  }

  if (diffInMilliseconds >= 3600000) {
    const diffInHours = Math.floor(diffInMilliseconds / 3600000);
    return rtf.format(-diffInHours, "hour");
  }

  if (diffInMilliseconds >= 60000) {
    const diffInMinutes = Math.floor(diffInMilliseconds / 60000);
    return rtf.format(-diffInMinutes, "minute");
  }

  return "Now";
}

export const formatCurrency = (value) => {
  if (!value) return;
  const options = { style: 'currency', currency: 'DKK', minimumFractionDigits: 2 };
  const numberFormat = new Intl.NumberFormat('da-DK', options);
  return numberFormat.format(Number(value));
};

export const compare2Value = (value1, value2)=> {
  if ((value1 && value2) && (value1 === value2)) 
    return true;
  else if(!value1 && !value2)
    return true;
  else 
    return false;
}

