import { useState, useRef } from "react";
import styled from "styled-components";
import Modal from "../Common/modal";
import { ButtonWithSpinner } from "../Common/buttons";
import { Text } from "../Common/typography";
import {
  deleteConfirmationCode,
  getConfirmationCode,
} from "../../helpers/jwt-token";
import { activateAcount } from "../../api/userService";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "../../toastProvider";


const Form = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;
const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
`;

const Activate = ({ isOpen, onClose, back }) => {
  const [values, setValues] = useState(["", "", "", ""]);
  const inputRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();

  const confirmAcount = useMutation({
    mutationFn: activateAcount,
    mutationKey: ["CONFIRM-ACOUNT"],
  });

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value) || value === "") {
      const newValues = [...values];
      newValues[index] = value;
      setValues(newValues);
      if (newValues.join("").length === 4) {
        const confirmCode = getConfirmationCode();
        if (confirmCode?.code == newValues.join("")) {
          setIsLoading(true);
          confirmAcount.mutate(
            { userId: confirmCode?.userId },
            {
              onError: (error) => addToast(error?.message, "warning"),
              onSuccess: () => deleteConfirmationCode(),
              onSettled: () => {
                setIsLoading(false);
                back();
                onClose();
              },
            }
          );
        } else addToast("Something went wrong", "warning");
      }

      if (value !== "" && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !values[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={"Konto Aktivering"}   
    >
      <hr style={{ color: "var(--color-pink)" }} />
      
        <Text>
          Indtast forsigtigt hvert ciffer i aktiveringskoden i den tilsvarende
          boks. Vores system vil automatisk flytte dig til den næste boks, mens
          du skriver. Når du har indtastet alle fire cifre, vil systemet hurtigt
          bekræfte koden. Hvis alt tjekker ud, vil din konto blive aktiveret!
        </Text>
 
      <Form>
        <InputContainer>
          {values.map((value, index) => (
            <input
              key={index}
              type="text"
              maxLength="1"
              value={value}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              ref={(el) => (inputRefs.current[index] = el)}
              style={inputStyle}
            />
          ))}
        </InputContainer>
        <ButtonWithSpinner
          type="submit"
          width={"12rem !important"}
          radius="7px"
          bakcground="--color-pink"
          hoverBackground="--color-pink9"
          padding={isLoading ? "1.2rem" : "0.5rem"}
          color="--color-white"
          hoverColor="--color-white"
          style={{ alignSelf: "center" }}
          disabled={isLoading}
          loading={isLoading}
          onClick={onClose}
        >
          {!isLoading && "Anullere"}
        </ButtonWithSpinner>
      </Form>
    </Modal>
  );
};

export default Activate;

const inputStyle = {
  width: "40px",
  height: "40px",
  fontSize: "24px",
  textAlign: "center",
  marginRight: "5px",
  border: "1.5px solid var(--color-pink)",
  borderRadius: "5px",
};
