import React from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { SimpleButton } from "../Common/buttons";
import { Avatar, RoundIcon as Icon } from "../Common/common";
import { HiOutlineDocument } from "react-icons/hi2";
import { RiContractLine, RiHome2Line } from "react-icons/ri";
import { MdPayment, MdLogout } from "react-icons/md";
import { IoDocumentsOutline } from "react-icons/io5";
import { GrUserAdmin } from "react-icons/gr";
import { PiStop } from "react-icons/pi";
import { ButtonText } from "../Common/typography";
import Logo from "../Common/logo";
import userIcon from "../../assets/default.png";
import { API_URL } from "../../constants";
import { breakpoints } from "../../constants";
import useBreakpoint from "../../hooks/useBreakpoints";
import { MdArrowBack } from "react-icons/md";

const Container = styled.div`
  background-color: var(--color-pink-light);
  border: 1px solid, var(--primary-border);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  width: 3.6rem;
  padding: 0;
  padding-bottom: 2.5rem;
  padding-top: 2rem;
  ul a {
    text-decoration: none;
  }
  transition: all 0.2s ease-in-out;
  @media (min-width: ${breakpoints.md}) {
    width: 14rem;
    padding: 1rem;
    padding-left: 2rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    width: 16rem;
  }
  .nav-logo {
    display: block;
    @media (min-width: ${breakpoints.md}) {
    display: none;
  }
  }
`;
const RoundIcon = styled(Icon)`
  width: 40px;
  height: 40px;
  font-size: 20px;
  @media (min-width: ${breakpoints.md}) {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }
`;
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;
const NavItem = styled(SimpleButton)`
  color: var(--secondary-text);
  gap: 7px;
  padding: 6px;
  width: 100%;
  justify-content: flex-start;
  @media (min-width: ${breakpoints.sm}) {
    width: auto;
  }
`;

const LogoutBtn = styled.span`
  cursor: pointer;
  padding-left: 10px;
  display: flex;
  p {
    display: none;
  }
  font-size: 28px;
  align-items: center;
  gap: 5px;
  @media (min-width: ${breakpoints.md}) {
    p {
      display: block;
      font-size: 14px;
      color: var(--primary-text);
    }
  }
`;

const Navigation = styled.ul`
  list-style: none;
  padding-top: 2rem;
  margin-top: 2rem;
  p {
    display: none;
  }
  @media (min-width: ${breakpoints.md}) {
    p {
      display: block;
    }
  }
`;
const LogoInner = styled.span`
  display: none;
  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`;

const PanelNav = ({ user }) => {
  const breakpoint = useBreakpoint();
  return (
    <Container>
      <nav>
        <LogoWrapper>

            <Link className="nav-logo" to="/private">
              <MdArrowBack color="var(--color-pink)" size={28} />
            </Link>
        
            <LogoInner>
              <Logo />
            </LogoInner>
       
        </LogoWrapper>
        <Navigation>
          <li style={{ marginBottom: "10px" }}>
            <NavLink exact to="profile" activeClassName="active" style={{}}>
              <NavItem hoverBackground="--transparent05">
                {" "}
                <Avatar
                  width={"40px"}
                  height={"40px"}
                  borderColor="--color-pink"
                  src={
                    user?.userImage ? `${API_URL}${user.userImage}` : userIcon
                  }
                  background="--color-blue1"
                />
                <ButtonText color="--primary-text">
                  {user ? `${user?.name} ${user?.lastname}` : "Ingen Navn"}
                </ButtonText>
              </NavItem>
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to="/profile/construction"
              activeClassName="active"
              style={{}}
            >
              <NavItem hoverBackground="--transparent05" radius="7px">
                {" "}
                <RoundIcon background="--color-pink">
                  <RiHome2Line color="var(--color-white)" />
                </RoundIcon>
                <ButtonText fontSize="14.5px" color="--primary-text">
                  Hjem
                </ButtonText>
              </NavItem>
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to="/profile/construction"
              activeClassName="active"
              style={{}}
            >
              <NavItem hoverBackground="--transparent05" radius="7px">
                {" "}
                <RoundIcon background="--color-pink">
                  <HiOutlineDocument color="var(--color-white)" />
                </RoundIcon>
                <ButtonText fontSize="14.5px" color="--primary-text">
                  Fakturer
                </ButtonText>
              </NavItem>
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/profile/construction" activeClassName="active">
              <NavItem hoverBackground="--transparent05" radius="7px">
                <RoundIcon background="--color-pink">
                  <RiContractLine color="var(--color-white)" />
                </RoundIcon>
                <ButtonText fontSize="14.5px" color="--primary-text">
                  Kontrakter
                </ButtonText>
              </NavItem>
            </NavLink>
          </li>
          <li>
            <NavLink to="/profile/construction" activeClassName="active">
              <NavItem hoverBackground="--transparent05" radius="7px">
                <RoundIcon background="--color-pink">
                  <MdPayment color="var(--color-white)" />
                </RoundIcon>
                <ButtonText fontSize="14.5px" color="--primary-text">
                  Betalinger
                </ButtonText>
              </NavItem>
            </NavLink>
          </li>
          <li>
            <NavLink to="/profile/construction" activeClassName="active">
              <NavItem hoverBackground="--transparent05" radius="7px">
                <RoundIcon background="--color-pink">
                  <IoDocumentsOutline color="var(--color-white)" />
                </RoundIcon>
                <ButtonText fontSize="14.5px" color="--primary-text">
                  Dokumenter
                </ButtonText>
              </NavItem>
            </NavLink>
          </li>
          <li>
            <NavLink to="/profile/construction" activeClassName="active">
              <NavItem hoverBackground="--transparent05" radius="7px">
                <RoundIcon background="--color-pink">
                  <PiStop color="var(--color-white)" />
                </RoundIcon>
                <ButtonText fontSize="14.5px" color="--primary-text">
                  Opsige dit lejemål
                </ButtonText>
              </NavItem>
            </NavLink>
          </li>
          {user?.isAdmin && (
            <li>
              <NavLink to="/profile/admin" activeClassName="active">
                <NavItem hoverBackground="--transparent05" radius="7px">
                  <RoundIcon background="--color-pink">
                    <GrUserAdmin color="var(--color-white)" />
                  </RoundIcon>
                  <ButtonText fontSize="14.5px" color="--primary-text">
                    Administrator
                  </ButtonText>
                </NavItem>
              </NavLink>
            </li>
          )}
        </Navigation>
      </nav>
      <Link style={{ textDecoration: "none" }} to="/logout">
        <LogoutBtn>
          <MdLogout color="var(--color-pink)" />
          <ButtonText color="--primary-text">Logout</ButtonText>
        </LogoutBtn>
      </Link>
    </Container>
  );
};

export default PanelNav;
