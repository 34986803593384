import styled from "styled-components";
import { SimpleButton } from "../Common/buttons";
import { Link } from "react-router-dom";
import { breakpoints } from "../../constants";


export const Container = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  z-index: 1;
  position: fixed;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isVisible }) =>
    isVisible ? "translateY(0)" : "translateY(-100%)"};

  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: flex-end;
  top: 0;
  left: 0;
  right: 0rem;
  .userText{
    display: none;
  }
  background-color: var(--color-white);
  a {
    text-decoration: none;
  }
  
  @media (min-width: ${breakpoints.sm}) {
   padding: 0 1rem;
  }
  @media (min-width: ${breakpoints.md}) {
    padding-left: 6rem;
    padding-right: 6rem;
    width: auto;
    justify-content: space-between;
    .userText{
      display: block;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-left: 8rem;
    padding-right: 8rem;
  }
`;
export const LogoWrapper = styled.div`
    display: none;
    @media (min-width: ${breakpoints.md}) {
      display: block;
  }
`;

export const NavItem = styled(SimpleButton)`
  color: var(--secondary-text);
  font-size: 16px;
  gap: 7px;
  padding: 6px 10px;
  width: 100%;
  justify-content: flex-start;
  border-radius: 7px;

`;

export const Navigation = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  li{margin-right: 2px}
  width: 100%;
  justify-content: flex-end;
  
  @media (min-width: ${breakpoints.sm}) {
    
  }
  @media (min-width: ${breakpoints.md}) {
     justify-content: flex-end;
  }
`;

export const LinkTo = styled(Link)`
  font-size: 16;
  display: flex;
  align-items: center;
  gap: 7px;
`;