import { PiUserCircle } from "react-icons/pi";
import { SimpleButton } from "../Common/buttons";
import styled from "styled-components";
import { ButtonText, HeaderTitle } from "../Common/typography";
import { IoIosSearch } from "react-icons/io";
import Dropdown from "../Common/dropdown";
import Input from "../Common/Input";
import { IoIosArrowDown } from "react-icons/io";
import { breakpoints } from "../../constants";

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100px;
  justify-content: space-between;
  background-color: var(--color-white);
  box-shadow: var(--cart-boxshadow);
  overflow: hidden;
  padding-top: 5px;
  width: 350px;
  @media (min-width: ${breakpoints.md}) {
    width: 660px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.5rem;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  .full-display {
    display: none;
  }
  @media (min-width: ${breakpoints.md}) {
    .full-display {
      display: block;
    }
    .full-nodisplay{display: none;}
    padding-left: 0;
  }
`;
const RightCorner = styled.div`
  background-color: var(--color-pink);
  margin-top: -5px;
  height: 65px;
  margin-left: 0px;
  width: 70px;
  button {
    width: 50px;
    margin-top: 10px;
  }
  @media (min-width: ${breakpoints.md}) {
    width: auto;
    margin-left: 7px;
    button {
      width: auto;
    }
  }
`;
const LeftCorner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-top: 15px;
  margin: 0;
  height: 60px;
  width: 40px;
  color: var(--secondary-text)
  font-weight: 600;
  display: none;
  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 40px;
  width: 120px;
  border-right: 1px solid var(--primary-border);
  padding-right: 10px;
`;

const Search = ({ user }) => {
  const color = "--topbar-button-color";

  return (
    <Container>
      <LeftCorner>
        <IoIosSearch fontSize={24} />
      </LeftCorner>
      <InputContainer>
        <InputWrapper className="full-display">
        <Dropdown
            dropdownBtn={
              <Input
                inputWidth="80px"
                width="110px"
                height="40px"
                background="--color-white"
                icon={
                  <IoIosArrowDown
                    color="var(--placeholder-icon)"
                    fontSize={16}
                  />
                }
                label=" Størelse"
                readOnly={true}
                cursor="pointer"
              />
            }
          ></Dropdown>
        </InputWrapper>
        <InputWrapper className="full-display">
          <Dropdown
            dropdownBtn={
              <Input
                inputWidth="80px"
                width="110px"
                height="40px"
                background="--color-white"
                icon={
                  <IoIosArrowDown
                    color="var(--placeholder-icon)"
                    fontSize={16}
                  />
                }
                label=" Størelse"
                readOnly={true}
                cursor="pointer"
              />
            }
          ></Dropdown>
        </InputWrapper>
        <InputWrapper className="full-display">
          <Dropdown
            dropdownBtn={
              <Input
                inputWidth="80px"
                width="110px"
                height="40px"
                background="--color-white"
                icon={
                  <IoIosArrowDown
                    color="var(--placeholder-icon)"
                    fontSize={16}
                  />
                }
                label=" Priser"
                readOnly={true}
                cursor="pointer"
              />
            }
          ></Dropdown>
        </InputWrapper >
        <InputWrapper className="full-display">
        <Dropdown
          dropdownBtn={
            <Input
              inputWidth="80px"
              width="110px"
              height="40px"
              background="--color-white"
              icon={
                <IoIosArrowDown color="var(--placeholder-icon)" fontSize={16} />
              }
              label=" Type"
              readOnly={true}
              cursor="pointer"
            />
          }
        ></Dropdown>
        </InputWrapper>



        <InputWrapper className="full-nodisplay">
        <Dropdown
          dropdownBtn={
            <Input
              inputWidth="90px"
              width="120px"
              height="40px"
              background="--color-white"
              icon={
                <IoIosArrowDown color="var(--placeholder-icon)" fontSize={16} />
              }
              label="Specifikationer"
              readOnly={true}
              cursor="pointer"
            />
          }
        ></Dropdown>
        </InputWrapper>
        <InputWrapper className="full-nodisplay">
        <Dropdown
          dropdownBtn={
            <Input
              inputWidth="90px"
              width="120px"
              height="40px"
              background="--color-white"
              icon={
                <IoIosArrowDown color="var(--placeholder-icon)" fontSize={16} />
              }
              label="valge Katagori"
              readOnly={true}
              cursor="pointer"
            />
          }
        ></Dropdown>
        </InputWrapper>
      </InputContainer>
      <RightCorner>
        <SimpleButton
          color="--color-white"
          hoverColor="--color-creame"
          style={{
            paddingLeft: "2rem",
            paddingRight: "2rem",
            margin: 0,
            height: 55,
          }}
        >
          <ButtonText color="--color-white" fontWeight="600">Søg</ButtonText>
        </SimpleButton>
      </RightCorner>
    </Container>
  );
};

export default Search;
