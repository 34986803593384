import styled from "styled-components";
import useAuthStore from "../store/useAuthUserStore";
import { AppContainer } from "../layout/layout";
import { CallOut } from "../components/Common/common";
import talking from "../assets/talking.jpeg";
import { Link, StrongTitle } from "../components/Common/typography";
import SliderGallary from "../components/sliderGallary/sliderGallary";
import { WideCart } from "../components/Common/common";
import { SimpleButton } from "../components/Common/buttons";
import { Text } from "../components/Common/typography";
import { breakpoints } from "../constants";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem;
  @media (min-width: ${breakpoints.sm}) {
    padding: 0 2rem;
  }
  @media (min-width: ${breakpoints.xl}) {
    padding: 0 8rem;
  }
`;
const SlideWrapper = styled.div`
  display: flex;
  padding: 1.5rem 2rem;
  @media (min-width: ${breakpoints.lg}) {
    padding: 2.5rem 8rem;
  }
`;

const CalloutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  gap: 10px;
  margin-top: 1rem;
  @media (min-width: ${breakpoints.lg}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 7rem;
    gap: 5rem;
  }
`;

const Private = ({ steps }) => {
  const { user } = useAuthStore();
  const { hide } = useOutletContext();
  useEffect(()=> {
      if (hide) hide(false);
  }, [hide]);

  return (
    <AppContainer>
      <Container>
        <SlideWrapper>
          <SliderGallary />
        </SlideWrapper>
        <WideCart>
          <img src={talking} />
          <div>
            <StrongTitle>Boligudlejning</StrongTitle>
            <Text>
              Estate Build er en af Sjællands førende virksomheder inden for
              boligudlejningsmarkedet og administrerer et bredt udvalg af
              boliglejemål over hele Sjælland. Hvis du leder efter et nyt hjem,
              kan du finde ledige lejemål på vores hjemmeside og også blive
              skrevet op på ventelisten. Som lejer hos Estate Build vil du have
              en fast kontaktperson, som vil være der for dig fra den dag, du
              flytter ind i lejemålet, til den dag du flytter ud. På den måde
              vil du altid have en person at henvende dig til, hvis du har
              spørgsmål angående dit lejemål.
            </Text>
            <Link style={{marginTop: "20px"}} href="mailto:kontakt@estatebuild.dk">
              <SimpleButton
                hoverBackground="--color-pink"
                width="8rem"
                padding="10px 1rem"
                color="--color-white"
                bakcground="--color-pink"
                hoverColor="--color-white"
              >
                Kontakt
              </SimpleButton>
            </Link>
          </div>
        </WideCart>
        <CalloutContainer>
          <CallOut titleColor="--color-pink" borderColor="--color-pink">
            <h4>VISION</h4>
            <p>
              Vi ønsker at Estate Builds boliger skal være tiltalende for hver
              enkelt beboer. Vi stræber efter at være synlige og relevante i
              lokalsamfundet og aktivt bidrage til den lokale byudvikling.
            </p>
          </CallOut>
          <CallOut>
            <h4>MISSION</h4>
            <p>
              Vi ønsker at Estate Builds boliger skal være tiltalende for hver
              enkelt beboer. Vi stræber efter at være synlige og relevante i
              lokalsamfundet og aktivt bidrage til den lokale byudvikling.
            </p>
          </CallOut>
        </CalloutContainer>
      </Container>
    </AppContainer>
  );
};

export default Private;
