import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { API_URL } from "../../constants";
import { IoMdArrowForward, IoMdArrowBack } from "react-icons/io";
import { SlideRight } from "../Common/animations";
import defaultImage from "../../assets/noPhoto-b.png";

const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
`;

const ImageContainer = styled.div`
  position: relative;
  &:hover .slide {
    opacity: 1;
  }
`;
const Slide = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(50% + 22px);
  display: flex;
  justify-content: space-between;
  padding: 5px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 9;
`;
const Image = styled.img`
  width: ${({ width }) => (width ? width : "120px")};
  height: ${({ height }) => (height ? height : "120px")};
  border-radius: ${({ radius }) => (radius ? radius : "0px")};
  object-fit: conver;
  transition: transform 0.5s ease-in-out;
`;

export default function SlideShow({ urls = [], width, height, radius }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const next = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % urls.length);
  });

  const prev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + urls.length) % urls.length);
  };

  const handleError = (event) => {
    event.target.src = defaultImage; // Set default image URL
  };

  return (
    <Container>
      <ImageContainer>
        <Image
          currentIndex={currentIndex}
          src={API_URL + urls[currentIndex]}
          width={width}
          height={height}
          radius={radius}
          onError={handleError}
        />
        <Slide className="slide">
          <IoMdArrowBack
            size={24}
            color="var(--color-pink)"
            style={{ boxShadow: "var(cart-boxshadow)", cursor: "pointer" }}
            onClick={prev}
          />
          <IoMdArrowForward
            size={24}
            color="var(--color-pink)"
            style={{ boxShadow: "var(cart-boxshadow)", cursor: "pointer" }}
            onClick={next}
          />
        </Slide>
      </ImageContainer>
    </Container>
  );
}
