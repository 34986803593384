import styled, { css } from "styled-components";
import { useEffect, useState } from "react";
import spinner from "../../assets/rolling.svg";
import { Text } from "./typography";

export const baseButton = css`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: var(--fontsize-button);
  font-weight: 500;
  text-shadow: var(--primary-text-shadow);
  border: none;
  border-radius: var(--primary-btn-radius);
  padding: 1rem;
  transition: all 0.2s ease 0s;
  color: var(--primary-text-btn);
  cursor: pointer;
  &:hover {
    filter: brightness(93%);
  }
  &:active {
    transform: scale(0.97);
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    color: var(--disabled-color);
    &:hover {
      cursor: not-allowed;
    }
  }
`;

export const SimpleButton = styled.button`
  ${baseButton}
  width: ${({ width }) => (width ? width : "auto")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "auto")};
  font-weight: ${({ weight }) => (weight ? weight : "500")};
  color: ${({ color }) => (color ? `var(${color})` : "var(--secondary-text)")};
  text-shadow: ${({ color }) =>
    `0 0 .65px ${color}` ? `var(${color})` : "var(--primary-text)"};
  border: 1px solid
    ${({ borderColor }) =>
      borderColor ? `var(${borderColor})` : "transparent"};
  border-radius: ${({ radius }) => (radius ? radius : "1.5rem")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "18px")};
  padding: ${({ padding }) => (padding ? padding : "0.8rem")};
  background-color: ${({ bakcground }) =>
    bakcground ? `var(${bakcground})` : "transparent"};
  &:hover {
    filter: none;
    background-color: ${({ hoverBackground }) =>
      hoverBackground ? `var(${hoverBackground})` : `var(--color-secondary1)`};
    color: ${({ hoverColor }) =>
      hoverColor ? `var(${hoverColor})` : "var(--secondary-text)"};
  }
`;

export const ButtonWithSpinner = styled(SimpleButton)`
  min-height: 40px;
  &::after {
    content: url(${spinner});
    color: white;
    position: absolute;
    top: 20%;
    right: 45%;
    transform: translateY(-50%, -50%);
    pointer-events: none;
    visibility: ${(props) => (props.loading ? "visible" : "hidden")};
  }
`;

export const ButtonSecondary = styled.button`
  ${baseButton}
  width: ${(props) => props.width || "fit-content"};
  min-height: 3.6rem;
  background-color: transparent;
  color: var(--secondary-text);
  &:hover {
    background-color: var(--color-secondary1);
  }
`;

export const RoundButton = styled(SimpleButton)`
  border-radius: 50%;
  height: ${({ size }) => size || "3rem"};
  width: ${({ size }) => size || "3rem"};
  padding: ${({ padding }) => padding || "0"};
  background-color: ${({ background }) =>
    background ? `var(${background})` : "transparent"};
  color: ${({ color }) => (color ? `var(${color})` : "var(--color-white)")};
  &: hover {
    background-color: ${({ hoverBackground }) =>
      hoverBackground ? `var(${hoverBackground})` : "transparent1"};
    color: ${({ hoverColor }) => (hoverColor ? `var(${hoverColor})` : "var(--color-white)")};
  }
  line-height: 14px;
`;

//**************************************************************************************************** */
const ButtonWrapper = styled.div`
  display: flex;
  position: relative;
  background-color: ${({ isOn }) =>
    isOn ? "var(--color-pink)" : "var(--color-pink-light)"};
  width: 50px;
  height: 1.5rem;
  border-radius: 12px;
  align-items: center;
  border: 1px solid var(--primary-border);
`;

const Button = styled.span`
  display: inline-block;
  border: 1px solid var(--primary-border);
  border-radius: 100%;
  height: 1.2rem;
  width: 1.2rem;
  background-color: var(--color-white);
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  transform: ${({ isOn }) => (isOn ? "translateX(28px)" : "translateX(3px)")};
  &:active {
    transform: scale(0.95);
  }
`;
const Container = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
`;

const SlideButton = ({ onChange, state, label }) => {
  const [isOn, setIsOn] = useState(state);

  const handleClick = () => setIsOn(!isOn);

  useEffect(() => onChange && onChange(isOn), [isOn]);

  return (
    <Container onClick={handleClick}>
      <ButtonWrapper isOn={isOn}>
        <Button isOn={isOn} />
      </ButtonWrapper>
      <Text fontWeight="400" fontSize="14px" color="--secondary-text">
        {label}
      </Text>
    </Container>
  );
};

export default SlideButton;
