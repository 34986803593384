import styled from "styled-components";
import { breakpoints } from "../../constants";
import defaultImage from "../../assets/default.png";

const { sm, md, lg, xl } = breakpoints;
export const CallOut = styled.div`
  padding: ${({ padding }) => padding || "2.5rem"};
  margin: 1rem 0;
  border-left: 4px solid
    ${({ borderColor }) => (borderColor ? `var(${borderColor})` : "#007bff")};
  background-color: ${({ background }) =>
    background ? `var(${background})` : "var(--color-white)"};
  box-shadow: var(--cart-boxshadow);
  border-radius: var(--cart-radius);
  color: #333;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  max-width: ${({ maxWidth }) => maxWidth || "34rem"};

  h4 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 17px;
    font-weight: 600;
    color: ${({ titleColor }) =>
      titleColor ? `var(${titleColor})` : "#007bff"};
  }

  p {
    margin: 0;
    font-size: ${({ fontSize }) => fontSize || "17px"};
    font-weight: ${({ fontWeight }) => fontWeight || "500"};
    color: ${({ color }) =>
      color ? `var(${color})` : "var(--secondary-text)"};
  }
  @media (min-width: ${lg}) { padding: 4rem; }
`;

export const WideCart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--web-wash);
  margin-top: 2rem;
  border-radius: var(--cart-radius);
  overflow: hidden;
  border-radius: 10px;
  img{
      width: 100%;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
  }
  div {
    padding: 15px;
    position: relative;
    background-color: var(--color-pink-light);
  }
  span {
    position: absolute;
    bottom: 3.5rem;
    right: 15rem;
    cursor: pointer;
  }
  @media (min-width: ${md}) {
    margin: 0 8rem;
    margin-top: 2rem;
    div{padding: 1rem;}
  }
  @media (min-width: ${lg}) {
    margin: 0 0;
    flex-direction: row;
    margin-top: 8rem;
    div{
      padding: 2rem;
      background-color: transparent;
    }
    img{
      width: 50%;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
`;

export const Splitter = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: ${({ margin }) => margin || "20px"} 0;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid var(--primary-border);
  }
  &::before {
    margin-right: 10px;
  }
  &::after {
    margin-left: 10px;
  }
  span {
    display: inline-block;
    padding: 0 10px;
    background: #fff;
    font-size: 13.5px;
    font-weight: 500;
    color: var(--color-light);
  }
`;

export const avatarError = (event) => {
  event.target.src = defaultImage; // Set default image URL
};

export const Avatar = styled.img`
  height: ${({height}) => height || "2rem"};
  width: ${({width}) => width || "2rem"};
  background-color: ${({ background }) => background ? `var(${background})` : "transparent"};
  border: 1px solid ${({ borderColor }) => borderColor? `var(${borderColor})` : "transparent"};
  object-fit: cover;
  border-radius: 50%;
  padding: ${({ padding }) => padding};
`;

export const RoundIcon = styled.span`
    height: ${({height})=> height || '1.8rem'};
    width: ${({width})=> width || '1.8rem'};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${({ fontSize }) => fontSize ? fontSize : "16px"};
    border-radius: 50%;
    color: ${({color}) => color ? `var(${color})` : 'var(--color-white)'};
    background-color: ${({background}) => background ? `var(${background})` : 'var(--secondary-bg-btn)'};
`;

