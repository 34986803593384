import styled from "styled-components";
import useApartmentStore from "../../store/useApartmentStore";
import { ButtonText, H3, H4, H5, Text } from "../Common/typography";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import {
  TbAerialLift,
  TbChartAreaLineFilled,
  TbChartAreaLine,
} from "react-icons/tb";
import { BsSearch, BsThreeDots } from "react-icons/bs";
import { MdOutlineEditNote, MdDelete } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { LuWarehouse } from "react-icons/lu";
import Input from "../Common/Input";
import SlideShow from "../slideShow/slideShow";
import { PLACE_TYPE, STATUS, FACILITY } from "../../constants";
import { RoundButton } from "../Common/buttons";
import Dropdown, { Item } from "../Common/dropdown";
import { useRef } from "react";
import { deleteApartment } from "../../api/httpServices";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "../../toastProvider";
import { breakpoints } from "../../constants";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  justify-content: flex-start;
  align-self: flext-start;
`;

const Span = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: var(--secondary-text);
`;

const PlaceContainer = styled.div`
  display: flex;
  box-shadow: var(--cart-boxshadow);
  border-radius: 7px;
  padding: 1rem;
  padding-bottom: 0.5rem;
  background-color: var(--color-white);
  width: 100%;
  gap: 10px;
  cursor: pointer;
`;
const PlaceBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;
const PlaceImgContainer = styled.div`
  border-radius: 10px;
  display: none;
  @media (min-width: ${breakpoints.md}) {
    display: block;
  }

`;
const PlaceDetailContainer = styled.div``;

const Header = styled.div`
  display: flex;
  width: 100%;
`;
const Justify = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
const DropWrapper = styled.span`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
`;

const HoverButton = styled(RoundButton)`
  color: var(--secondary-text);
  border: 1px solid, var(--primary-border);
  padding: 0;
  height: 2.5rem;
  width: 2.5rem;
  background-color: var(--transparent03);
  display: none;
  ${PlaceBody}:hover & {
    display: block;
    color: var(--primary-text);
  }
  &:active {
    transform: scale(0);
  }
`;
const DropContent = styled.div`
  padding: 1rem 0.8rem;
`;

const statusColor = {
  occupied: "var(--color-orange)",
  sold: "var(--color-red)",
  available: "var(--color-green)",
  undefined: "var(--color-blue)"
};
const SearchWrapper = styled.div`
  padding-bottom: 15px;
`;

function Property({ apartment, addState }) {
  const dropdownRef = useRef(null);
  const address = apartment?.address;
  const size = apartment?.size;

  const { addToast } = useToast();
  const { rmApartment } = useApartmentStore();

  const removeApartment = useMutation({
    mutationKey: ["DELETE_AP"],
    mutationFn: deleteApartment,
  });

  const handleRemove = () => {
    removeApartment.mutate(apartment, {
      onError: (error) => addToast(error.response?.data?.message, "warning"),
      onSuccess: (data) => {
        rmApartment(data);
      },
      onSettled: () => {
        if (dropdownRef?.current) dropdownRef.current.closeDropdown();
      },
    });
  };
  const handleMouseLeave = () => {
    if (dropdownRef?.current) dropdownRef.current.closeDropdown();
  };

  const handleClick = () => {
    addState(apartment);
    if (dropdownRef?.current) dropdownRef.current.closeDropdown();
  };

  return (
    <PlaceContainer>
      <PlaceImgContainer>
        <SlideShow urls={apartment?.images} radius={"10px"}/>
      </PlaceImgContainer>
      <PlaceBody onMouseLeave={handleMouseLeave}>
        <DropWrapper>
          <Dropdown
            ref={dropdownRef}
            width="300px"
            translate="-260px"
            dropdownBtn={
              <HoverButton>
                <BsThreeDots />
              </HoverButton>
            }
          >
            <DropContent>
              <Item onClick={handleClick} hoverBackground="--transparent05">
                <MdOutlineEditNote size={24} /> 
                <ButtonText>Opdatere</ButtonText>
              </Item>
              <Item onClick={handleRemove} hoverBackground="--transparent05">
                <MdDelete size={20} /> 
                <ButtonText style={{marginLeft: 3}}>Slet</ButtonText>
              </Item>
            </DropContent>
          </Dropdown>
        </DropWrapper>
        <Header>
          <div>
            <H5>{`${address?.street} ${address?.zip} ${address?.city}`}</H5>
            <Justify>
              <H5>{apartment?.title}</H5>
              <Header style={{ marginLeft: "15px" }}>
                <Justify>
                  <HiOutlineBuildingOffice2
                    size={16}
                    color="var(--placeholder-icon)"
                  />
                  <Text fontSize={"14px"}>
                    {PLACE_TYPE[apartment?.type]} |{" "}
                  </Text>
                </Justify>
                <Justify>
                  <TbAerialLift size={16} color="var(--placeholder-icon)" />
                  <Text fontSize={"14px"}>{FACILITY[apartment?.facility]}</Text>
                </Justify>
              </Header>
            </Justify>
          </div>
          <Justify style={{ alignSelf: "flex-start", marginLeft: "auto" }}>
            <GoDotFill size={18} color={statusColor[apartment?.status]} />
            <Text fontSize={"14px"}>{STATUS[apartment?.status]}</Text>
          </Justify>
        </Header>

        <PlaceDetailContainer>
          <div>
            <Justify>
              <TbChartAreaLineFilled
                size={18}
                color="var(--placeholder-icon)"
              />
              <H5>Bolige storelse {size?.squareFeet}m²</H5>
            </Justify>
            <Justify>
              <TbChartAreaLine size={18} color="var(--placeholder-icon)" />
              <H5>
                Land Areal {size?.squareFeetLand}
                <Span>m2</Span>
              </H5>
              <H4 style={{ marginLeft: "auto" }} color="--color-blue">
                {apartment?.price}
              </H4>
            </Justify>
          </div>
        </PlaceDetailContainer>
      </PlaceBody>
    </PlaceContainer>
  );
}

export default function Apartments({ addState }) {
  const { apartments } = useApartmentStore();

  return (
    <Container>
      <SearchWrapper>
        <Input
          background="--color-white"
          border="--primary-border"
          icon={
            <BsSearch
              color="var(--placeholder-icon)"
              size={16}
              style={{ marginRight: "15px" }}
            />
          }
          icon1={
            <LuWarehouse
              size={16}
              color="var(--placeholder-icon)"
              style={{
                marginLeft: "10px",
                borderRight: "1px solid var(--primary-border)",
                paddingRight: "10px",
                width: "30px",
              }}
            />
          }
          iconPadding="1px"
          label="Sog for lejligheder"
        />
      </SearchWrapper>
      {apartments.map((apartment) => (
        <Property apartment={apartment} addState={addState} />
      ))}
    </Container>
  );
}
