import { useState } from "react";
import styled from "styled-components";
import { FaArrowLeft } from "react-icons/fa";
import Input from "../Common/Input";
import { ButtonWithSpinner, RoundButton } from "../Common/buttons";
import { REGISTER_MESSAGE } from "../../constants";
import { Splitter } from "../Common/common";
import { FadeIn } from "../Common/animations";
import { createUser } from "../../api/userService";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "../../toastProvider";
import Activate from "./activation";
import { ButtonText } from "../Common/typography";

// -------- Styles ---------
const Container = styled.div`
  animation: ${FadeIn} 0.5s;
`;
const Form = styled.form`
  padding: rem;
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const CodeLink = styled.p`
  align-self: flex-end;
  color: var(--color-blue);
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  margin-bottom: 5px;
  &:hover {
    filter: brightness(85%);
  }
`;

//----------- Component ------------
const SignUp = ({ back }) => {
  const { addToast, toasts } = useToast();
  const [data, setData] = useState({ isLoading: false });
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(!isOpen);

  const addUser = useMutation({
    mutationFn: createUser,
    mutationKey: ["SIGN-UP"],
  });
  const handleChange = (e) => {
    const { name, value } = e.currentTarget;
    setData((pre) => ({ ...pre, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.password !== data.repassword) {
      addToast(
        "Intasted password er ikke samme, venligst prøver igen!",
        "warning"
      );
      return;
    }
    setData((pre) => ({ ...pre, isLoading: true }));
    addUser.mutate(data, {
      onError: (error) => addToast(error?.message, "warning"),
      onSuccess: () => {
        addToast(REGISTER_MESSAGE, "info");
        setData({ isLoading: false });
        setIsOpen(true);
      },
      onSettled: () => setData((pre) => ({ ...pre, isLoading: false })),
    });
  };

  return (
    <Container>
      <RoundButton

        size={20}
        padding="0px"
        hoverBackground="--transparent"
        style={{ marginTop: "-40px", marginBottom: "15px" }}
        onClick={() => back()}
      >
        <ButtonText>
          <FaArrowLeft />
        </ButtonText>
      </RoundButton>
      <Form onSubmit={handleSubmit}>
        <Input
          label="Fornavn"
          name="name"
          type="text"
          onchange={handleChange}
          value={data?.name}
          border="--primary-border"
          background="--transparent"
          width="100%"
        />
        <Input
          label="Efternavn"
          name="last"
          type="text"
          onchange={handleChange}
          value={data?.last}
          border="--primary-border"
          background="--transparent"
          width="100%"
        />
        <Input
          label="Email address"
          name="email"
          type="email"
          onchange={handleChange}
          value={data?.email}
          border="--primary-border"
          background="--transparent"
          width="100%"
        />
        <Input
          label="Password"
          name="password"
          type="password"
          value={data?.password}
          onchange={handleChange}
          border="--primary-border"
          background="--transparent"
          width="100%"
        />
        <Input
          label="Re-Password"
          name="repassword"
          type="password"
          value={data?.repassword}
          onchange={handleChange}
          border="--primary-border"
          background="--transparent"
          width="100%"
        />

        <ButtonWithSpinner
          color="--color-white"
          hoverColor="--color-white"
          bakcground="--color-pink"
          hoverBackground="--color-pink9"
          padding={"9px"}
          type="submit"
          disabled={data.isLoading}
          loading={data.isLoading}
        >
          {!data.isLoading && (
            <ButtonText color="--color-white">Opret</ButtonText>
          )}
        </ButtonWithSpinner>
        <Splitter margin="10px">
          <span> Opret med</span>
        </Splitter>
      </Form>
      <CodeLink onClick={onClose}>Aktivering code?</CodeLink>

      <Activate isOpen={isOpen} onClose={onClose} back={back} />
    </Container>
  );
};

export default SignUp;
