
const constants = {
  LOGIN: "LOGIN",
  SIGN_UP: "SIGN-UP",
  LOGOUT: "LOGOUT",
  FORGOT_PASS: "FORGOT-PASSWORD",
  AUTH_USER: "AUTH-USER",
  FETCH_USERS: "FETCH-USERS",
  NEW_POST: "NEW_POST",
  GET_POSTS: "GET_POSTS",
};

export const API_URL = "https://estatebuild.dk/api/"; //"http://localhost:3002/api/"; 
export const fileType = {
  VIDEO: "video",
  IMAGE: "image",
};

export const REGISTER_MESSAGE = `En besked blev sent i din email. Du skal aktiver din konto før du kan bruge den.`;

export const POLICY = `By clicking Create, you agree to our Terms. 
Learn how we collect, use and share your data in our Data Policy and how we use cookies and 
similar technology in our Cookies Policy. You may receive Notifications from us any time.`;

export const PASSWORD_RESET_MESSAGE = `Af sikkerhedsmæssige årsager udløber dette link om 30 minutter. 
Hvis du har brug for et nyt link, kan du anmode om en ny nulstilling af adgangskoden fra vores hjemmeside.`;

export const SUCCESS_RESET_MESSAGE = `Vi har sendt en e-mail til din registrerede adresse med detaljerede instruktioner om, 
hvordan du nulstiller din adgangskode.`;

export const breakpoints = {
  sm: "340px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
};

export const toastProps = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "light",
};

export const components = { MEDIA: "media", MAP: "map" };

export const STATUS = { occupied: "Lejet ud", sold: "Solgt", available: "Tilgængelig" };
export const FACILITY = { elevator: "Elevator", altan: "Altan", husdyr: "Husdyr tilladt" };
export const PLACE_TYPE = {
  villa: "Villa",
  terraced: "Rækkehus",
  apartment: "Lejlighed",
  landlord: "Landejendom",
  office: "Kontor"
}

export const reklamText = `   Vores udlejningsteam står klar til at hjælpe dig med at finde den
              helt rette bolig, der passer til dine ønsker og behov. Tøv ikke
              med at kontakte dem.`;

export default constants;
