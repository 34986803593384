import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { BsCameraFill } from "react-icons/bs";
import Input from "../Common/Input";
import { ButtonWithSpinner, RoundButton } from "../Common/buttons";
import { Avatar, Splitter } from "../Common/common";
import { FadeInNoSlide } from "../Common/animations";
import { ButtonText, H6 } from "../Common/typography";
import userIcon from "../../assets/default.png";
import { API_URL, breakpoints } from "../../constants";
import { updateUser } from "../../api/userService";
import { useMutation } from "@tanstack/react-query";
import { compare2Value } from "../../helpers/common";
import { useToast } from "../../toastProvider";
import useAuthStore from "../../store/useAuthUserStore";


// -------- Styles ---------
const Container = styled.div`
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 350px;
  padding: 2rem 1rem;
  animation: ${FadeInNoSlide} 0.5s;
  @media (min-width: ${breakpoints.md}) { 
    padding: 2rem 5rem; 
    width: auto;
  }
`;
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 40rem;
  background-color: var(--color-white);
  margin-top: 5rem;
  padding: 0 1.5rem;
  border-radius: 10px;
  padding-bottom: 1rem;
`;
const Form = styled.form`
  padding: rem;
  display: flex;
  flex-direction: column;
  gap: 7px;
  button {
    margin: auto;
    width: 20rem;
  }
  button{
    width: 220px;
  }
  @media (min-width: ${breakpoints.md}) { 
    button{
      width: 100%;
    }
  }
`;
const AvatarWrapper = styled.div`
  position: relative;
  width: 10.5rem;
  height: 10.5rem;
  margin-top: -5rem;
  margin-left: 5rem;
  background-color: var(--color-white);
  border-radius: 50%;
  padding: 5px;
`;
const ButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 10px;
  left: 110px;
  border-radius: 50%;
  background-color: var(--transparent1);
`;
const Label = styled.p`
  font-size: 15px;
  color: var(--secondary-text);
  margin-bottom: 0px;
  font-weight: 500;
  margin-top: 10px;
`;

//----------- Component ------------
const Profile = ({ user }) => {
  const { setUser } = useAuthStore();
  const fileInputRef = useRef(null);
  const { addToast } = useToast();
  const [data, setData] = useState({ isLoading: false });
  const imageUrl = data?.userImage ? `${API_URL}${data.userImage}` : null;
  useEffect(() => {
    if (user) {
      setData((pre) => ({ ...pre, ...user }));
    }
  }, [user]);
  const update = useMutation({
    mutationFn: updateUser,
    mutationKey: ["UPDATE_USER"],
  });

  const handleChange = (e) => {
    const { name, value } = e.currentTarget;
    setData((pre) => ({ ...pre, [name]: value }));
  };

  const handleFileChange = (e) => {
    const image = e.target.files[0];
    if (!image) return;
    const uri = URL.createObjectURL(e.target.files[0]);
    setData((pre) => ({ ...pre, userImage: image, uri }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!compare2Value(data?.password, data?.rePassword)) {
      addToast("Adgangskoden stemme ikke!", "warning");
      return;
    }
    setData((pre) => ({ ...pre, isLoading: true }));
    delete data?.rePassword;
    const { isLoading, ...restData } = data;

    update.mutate(restData, {
      onError: (error) => console.log(error.response?.data?.message),
      onSuccess: (data) => setUser(data),
      onSettled: () => setData((pre) => ({ ...pre, isLoading: false })),
    });
  };

  return (
    <Container>
      <InnerContainer>
        <AvatarWrapper>
          <Avatar
            height="10rem"
            width="10rem"
            borderColor={user?.userImage ? "" : "--color-blue2"}
            src={data?.uri ? data?.uri : imageUrl || userIcon}
            background="--color-blue1"
          />
          <ButtonWrapper>
            <RoundButton
              borderColor="--primary-border"
              size="2.5rem"
              bakcground="--transparent2"
              hoverBackground="--transparent3"
              onClick={(e) => fileInputRef.current.click()}
            >
              <ButtonText color="--color-white" hoverColor="--color-smoke">
                <BsCameraFill size={20} />
              </ButtonText>
            </RoundButton>
          </ButtonWrapper>
        </AvatarWrapper>
        <input
          ref={fileInputRef}
          onChange={handleFileChange}
          type="file"
          accept="image/*"
          style={{ display: "none" }}
        />
        <Form onSubmit={handleSubmit}>
          <Label>Fornavn</Label>
          <Input
            labelColor="--color-light"
            label="Navnet skal være minst 2 bokstave"
            name="name"
            type="text"
            onchange={handleChange}
            value={data?.name}
            border="--primary-border"
            background="--transparent"
            width="100%"
          />
          <Label>Efternavn</Label>
          <Input
            labelColor="--color-light"
            label="Efternavnet skal være minst 2 bokstave"
            name="lastname"
            type="text"
            onchange={handleChange}
            value={data?.lastname}
            border="--primary-border"
            background="--transparent"
            width="100%"
          />
          <Label>Email</Label>
          <Input
            labelColor="--color-light"
            label="Email address kraves"
            name="email"
            type="email"
            onchange={handleChange}
            value={data?.email}
            border="--primary-border"
            background="--transparent"
            width="100%"
          />
          <Splitter>
            <H6 color="--disabled-color">Skift adgangskode</H6>
          </Splitter>
          <Label>Adgangskode</Label>
          <Input
            labelColor="--color-light"
            label="Ny adgangskode"
            name="password"
            type="password"
            value={data?.password}
            onchange={handleChange}
            border="--primary-border"
            background="--transparent"
            width="100%"
          />

          <Input
            labelColor="--color-light"
            label="Gentage ny adgangskode"
            name="rePassword"
            type="password"
            value={data?.rePassword}
            onchange={handleChange}
            border="--primary-border"
            background="--transparent"
            width="100%"
          />

          <ButtonWithSpinner
            style={{ marginTop: "20px" }}
            color="--color-white"
            hoverColor="--color-white"
            bakcground="--color-pink"
            width="100%"
            radius="7px"
            hoverBackground="--color-pink9"
            padding="9px"
            type="submit"
            disabled={data.isLoading}
            loading={data.isLoading}
          >
            {!data.isLoading && (
              <ButtonText fontWeight="600" color="--color-white">
                Gem
              </ButtonText>
            )}
          </ButtonWithSpinner>
        </Form>
      </InnerContainer>
    </Container>
  );
};

export default Profile;
