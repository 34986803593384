import useAuthStore from "../../store/useAuthUserStore";
import { unAuthenticate } from "../../api/userService";
import { useEffect } from "react";
import styled from "styled-components";
import { H3, H4, H6 } from "../Common/typography";
import { useQuery } from "@tanstack/react-query";
import { ReactComponent as LoadingIcon } from "../../assets/loading.svg";
import spinner from "../../assets/rolling.svg";
import Logo from "../Common/logo";
import { IoShieldCheckmark } from "react-icons/io5";

const Container = styled.div`
    width; 100%;
    min-height: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export default function Logout() {
    const { setUser } = useAuthStore();
    const { error, isLoading, isSuccess } = useQuery({
      queryKey: ["LOG_OUT"],
      queryFn: unAuthenticate,
    });

    useEffect(() => {
      if (!isLoading && isSuccess) setUser({});
    }, [isLoading, isSuccess]);

    useEffect(() => {
      if (!isLoading) window.location.href = '/login';
    }, [isLoading]);

  return (
    <Container>
      <InnerContainer>
        {isLoading && (
          <WrapperText>
            <LoadingIcon />
            <H3>Igange med at logge dig ud</H3>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 7,
                paddingTop: 30,
              }}
            >
              <IoShieldCheckmark size={22} />
              <H6 fontWeight="700"> SECURED DATA PROCESSING</H6>
            </div>
            <Logo />
          </WrapperText>
        )}
      </InnerContainer>
    </Container>
  );
}
