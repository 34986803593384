import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { formatCurrency } from "../../helpers/common";
//------------ Style ----------------
const InputWrapper = styled.div`
    display: flex;
    justify-content; center;
    overflow: visible;
    position: relative;
    border-radius: ${({ radius }) => radius || "0px"};
    border: 1px solid ${({ borders }) =>
      borders ? `var(${borders})` : "transparent"};
    width: ${({ width }) => width || "auto"};
    border-radius: ${({ radius }) => radius || "7px"};
    background-color: ${({ background }) =>
      background ? `var(${background})` : "var(--input-background)"};

    transition: .3s all;
    &:hover{
            filter: brightness(97%);
        }
    &::before{
        background-color: var(--color-red);
        border-radius: 5px;
        font-size: 14px;
    }
`;
const CustomeInput = styled.input`
  padding-left: 8px;
  border: none;
  background-color: transparent;
  border-left: 1px solid
    ${({ borderColor }) =>
      borderColor ? `var(${borderColor})` : "transparent"};
  height: ${({ height }) => height || "38px"};
  width: ${({ inputWidth }) => inputWidth || "100%"};
  font-size: 15px;
  font-weight: ${({ fontWeight }) => fontWeight ? `${fontWeight}` : "500"};
  color: ${({ color }) => (color ? `var(${color})` : "var(--secondary-text)")};
  text-shadow: var(--primary-text-shadow);
  letter-spacing: 0.3px;
  transition: all 0.3s;
  cursor: ${({ cursor }) => cursor || "text"};
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: var(--placeholder-text);
    letter-spacing: 0.4px;
    margin-left: 5px;
  }
  &:hover::placeholder {
    color: var(--secondary-text);
    transition: all .3s ease;
  }
  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: ${({ showSpin }) => (showSpin ? "block" : "none")};
    margin: 0;
  }
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${({ iconPadding }) => iconPadding ? iconPadding : "0px"};
  padding-left: ${({ iconPadding }) => iconPadding ? iconPadding : "5px"};
  background-color: ${({ iconBgColor }) =>
    iconBgColor ? `var(${iconBgColor})` : "transparent"};
`;

// ------------- Component -------------------
const Input = (props) => {
  const ref = useRef();

  const handleFocus = (e) => {
    if (props.type === "currency"){
        const formattedValue = e.currentTarget.value;
        const commaIndex = formattedValue.indexOf(",");
    
        const originalString =
          commaIndex !== -1
            ? formattedValue.substring(0, commaIndex)
            : formattedValue;

        e.currentTarget.value = originalString;
        props.onchange(e);
    }
  };

  const handleBlur = (e) => {
    if (props.type === "currency" && e.currentTarget.value) {
      e.currentTarget.value = formatCurrency(e.currentTarget.value);
      props.onchange(e);
    }
  };

  const onchange = (e) => {
    if (props.type === "currency") {
      const numericValue = e.currentTarget.value.replace(/[^0-9,]/g, "");
      const parts = numericValue.split(",");
      if ((numericValue || numericValue === "") && parts.length < 3) {
        e.currentTarget.value = numericValue;
        props.onchange(e);
      }
    } else {
      props.onchange(e);
    }
  };
  return (
    <InputWrapper
      ref={ref}
      height={props.height}
      width={props.width}
      background={props.background}
      radius={props.radius}
      borders={props.border}
    >
      <IconContainer
        iconPadding={props.paddingRight}
        iconBgColor={props.iconBgColor}
      >
        {props.icon1}
      </IconContainer>
      <CustomeInput
        labelColor={props.labelColor}
        borderColor={props.borderColor}
        inputWidth={props.inputWidth}
        onFocus={handleFocus}
        onBlur={handleBlur}
        type={props.type}
        showSpin={props.showSpin}
        name={props.name}
        placeholder={props.label}
        value={props.value}
        onChange={onchange}
        readOnly={props.readOnly}
        cursor={props.cursor}
        height={props.height}
        autoComplete={props.autoComplete}
      />
      <IconContainer
        iconPadding={props.paddingRight}
        iconBgColor={props.iconBgColor}
      >
        {props.icon}
      </IconContainer>
    </InputWrapper>
  );
};

export default Input;
