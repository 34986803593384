import { useRef } from "react";
import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";
import Input from "../Common/Input";
import Dropdown from "../Common/dropdown";
import CustomTextarea from "../Common/textArea";
import { STATUS, FACILITY, PLACE_TYPE } from "../../constants";
import { Item } from "../Common/dropdown";
import { TbMeterSquare } from "react-icons/tb";
import { Splitter } from "../Common/common";
import SlideButton from "../Common/buttons";
import { FaRestroom, FaBed } from "react-icons/fa";
import { TbStairsUp } from "react-icons/tb";

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;
const Group = styled.div`
  display: flex;
  gap: 7px;
`;
const DropContent = styled.div`
  padding: 1rem;
`;

const getKeyByValue = (obj, value) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

export default function Form({ data, setData, handleSubmit, children }) {
  const dropdownRef = useRef(null);

  const handleChangeObj = (e, obj) => {
    const { name, value } = e.currentTarget;
    const parentObj = { ...data?.[obj] };
    parentObj[name] = value;
    setData((pre) => ({ ...pre, [obj]: parentObj }));
  };

  const handleChange = (e, name, value) => {
    if (
      e.currentTarget &&
      e.currentTarget.name &&
      e.currentTarget.value !== undefined
    ) {
      const { name, value } = e.currentTarget;
      setData((pre) => ({ ...pre, [name]: value }));
    } else setData((pre) => ({ ...pre, [name]: value }));

    if (dropdownRef?.current) dropdownRef.current.closeDropdown();
    console.log(data)
  };

  const onCheck = (state) => {
    setData((pre) => ({ ...pre, isPrivate: state }));
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Input
        label="Titel"
        name="title"
        type="text"
        onchange={handleChange}
        value={data?.title || ''}
        border="--primary-border"
        background="--color-white"
      />
      <SlideButton
        onChange={onCheck}
        state={data?.isPrivate}
        label="Lejemålet er private"
      />
      <Dropdown
        ref={dropdownRef}
        dropdownBtn={
          <Input
            readOnly={true}
            border="--primary-border"
            background="--color-white"
            value={PLACE_TYPE[data?.type] || ''}
            icon={
              <IoIosArrowDown
                color="var(--placeholder-icon)"
                fontSize={16}
                style={{ marginRight: "12px" }}
              />
            }
            label=" Type"
          />
        }
      >
        <DropContent>
          <Item
            onClick={(e) =>
              handleChange(
                e,
                "type",
                getKeyByValue(PLACE_TYPE, PLACE_TYPE.villa)
              )
            }
            hoverBackground="--transparent05"
          >
            {PLACE_TYPE.villa}
          </Item>
          <Item
            onClick={(e) =>
              handleChange(
                e,
                "type",
                getKeyByValue(PLACE_TYPE, PLACE_TYPE.apartment)
              )
            }
            hoverBackground="--transparent05"
          >
            {PLACE_TYPE.apartment}
          </Item>
          <Item
            onClick={(e) =>
              handleChange(
                e,
                "type",
                getKeyByValue(PLACE_TYPE, PLACE_TYPE.terraced)
              )
            }
            hoverBackground="--transparent05"
          >
            {PLACE_TYPE.terraced}
          </Item>
          <Item
            onClick={(e) =>
              handleChange(
                e,
                "type",
                getKeyByValue(PLACE_TYPE, PLACE_TYPE.landlord)
              )
            }
            hoverBackground="--transparent05"
          >
            {PLACE_TYPE.landlord}
          </Item>
          <Item
            onClick={(e) =>
              handleChange(
                e,
                "type",
                getKeyByValue(PLACE_TYPE, PLACE_TYPE.office)
              )
            }
            hoverBackground="--transparent05"
          >
            {PLACE_TYPE.office}
          </Item>
        </DropContent>
      </Dropdown>
      <Input
        label="Indtaste prisen i dk"
        name="price"
        type="currency"
        onchange={handleChange}
        value={data?.price || ''}
        border="--primary-border"
        background="--color-white"
        width="100%"
        autoComplete="off"
      />
      <Splitter margin="5px" />
      <Group>
        <Input
          label="Bolige storelsen"
          name="squareFeet"
          type="number"
          onchange={(e) => handleChangeObj(e, "size")}
          value={data.size?.squareFeet || ''}
          border="--primary-border"
          background="--color-white"
          width="50%"
          icon={
            <TbMeterSquare
              color="var(--placeholder-icon)"
              style={{ margin: "0 5px" }}
            />
          }
          iconBgColor="--transparent03"
        />
        <Input
          label="Land storelsen"
          name="squareFeetLand"
          type="number"
          onchange={(e) => handleChangeObj(e, "size")}
          value={data.size.squareFeetLand || ''}
          border="--primary-border"
          background="--color-white"
          width="50%"
          icon={
            <TbMeterSquare
              color="var(--placeholder-icon)"
              style={{ margin: "0 5px" }}
            />
          }
          iconBgColor="--transparent03"
        />
        <Input
          label="Antal etager"
          name="numberOfPlan"
          type="number"
          showSpin={true}
          onchange={(e) => handleChangeObj(e, "size")}
          value={data.size?.numberOfPlan || ''}
          border="--primary-border"
          background="--color-white"
          icon1={<TbStairsUp size={22} color="var(--placeholder-icon)"/>}
        />
      </Group>

      <Group>
        <Input
          label="Antal vearelse"
          name="numberOfRooms"
          type="number"
          showSpin={true}
          onchange={(e) => handleChangeObj(e, "size")}
          value={data.size?.numberOfRooms || ''}
          border="--primary-border"
          background="--color-white"
          width="50%"
          icon1={<FaBed size={22} color="var(--placeholder-icon)"/>}
        />
        <Input
          label="Antal badevearelse"
          name="numberOfBathrooms"
          type="number"
          showSpin={true}
          onchange={(e) => handleChangeObj(e, "size")}
          value={data.size?.numberOfBathrooms || ''}
          border="--primary-border"
          background="--color-white"
          width="50%"
          icon1={<FaRestroom size={22} color="var(--placeholder-icon)"/>}
        />
        <Input
          label="Hvilken etage"
          name="floor"
          type="number"
          showSpin={true}
          onchange={(e) => handleChangeObj(e, "size")}
          value={data.size?.floor || ''}
          border="--primary-border"
          background="--color-white"
          width="50%"
        />
      </Group>

      <Dropdown
        ref={dropdownRef}
        dropdownBtn={
          <Input
            readOnly={true}
            border="--primary-border"
            label="Facility"
            name="facility"
            value={FACILITY[data?.facility] || ''}
            background="--color-white"
            icon={
              <IoIosArrowDown
                color="var(--placeholder-icon)"
                fontSize={16}
                style={{ marginRight: "12px" }}
              />
            }
          />
        }
      >
        <DropContent>
          <Item
            onClick={(e) =>
              handleChange(
                e,
                "facility",
                getKeyByValue(FACILITY, FACILITY.elevator)
              )
            }
            hoverBackground="--transparent05"
          >
            {FACILITY.elevator}
          </Item>
          <Item
            onClick={(e) =>
              handleChange(
                e,
                "facility",
                getKeyByValue(FACILITY, FACILITY.altan)
              )
            }
            hoverBackground="--transparent05"
          >
            {FACILITY.altan}
          </Item>
          <Item
            onClick={(e) =>
              handleChange(
                e,
                "facility",
                getKeyByValue(FACILITY, FACILITY.husdyr)
              )
            }
            hoverBackground="--transparent05"
          >
            {FACILITY.husdyr}
          </Item>
        </DropContent>
      </Dropdown>
      <Dropdown
        ref={dropdownRef}
        dropdownBtn={
          <Input
            readOnly={true}
            value={STATUS[data?.status] || ''}
            border="--primary-border"
            label="Status"
            name="status"
            background="--color-white"
            icon={
              <IoIosArrowDown
                color="var(--placeholder-icon)"
                fontSize={16}
                style={{ marginRight: "12px" }}
              />
            }
          />
        }
      >
        <DropContent>
          <Item
            onClick={(e) =>
              handleChange(e, "status", getKeyByValue(STATUS, STATUS.available))
            }
            hoverBackground="--transparent05"
          >
            {STATUS.available}
          </Item>
          <Item
            onClick={(e) =>
              handleChange(e, "status", getKeyByValue(STATUS, STATUS.occupied))
            }
            hoverBackground="--transparent05"
          >
            {STATUS.occupied}
          </Item>
          <Item
            onClick={(e) =>
              handleChange(e, "status", getKeyByValue(STATUS, STATUS.sold))
            }
            hoverBackground="--transparent05"
          >
            {STATUS.sold}
          </Item>
        </DropContent>
      </Dropdown>

      <Splitter margin="5px" />
      <Input
        label="Gade navn og hus/lejlehed numer"
        name="street"
        type="text"
        onchange={(e) => handleChangeObj(e, "address")}
        value={data.address?.street || ''}
        border="--primary-border"
        background="--color-white"
      />
      <Group>
        <Input
          label="Post numer"
          name="zip"
          type="text"
          onchange={(e) => handleChangeObj(e, "address")}
          value={data.address?.zip || ''}
          border="--primary-border"
          background="--color-white"
        />
        <Input
          label="By"
          name="city"
          type="text"
          onchange={(e) => handleChangeObj(e, "address")}
          value={data.address?.city || ''}
          border="--primary-border"
          background="--color-white"
        />
      </Group>
      <CustomTextarea
        label="Skrive om lejemålet"
        handleInput={handleChange}
        value={data?.description || ''}
        name="description"
        background="white"
        minheight="4rem"
      />
      {children}
    </FormContainer>
  );
}
