import { useState, useEffect } from "react";
import { SimpleButton } from "../Common/buttons";
import { PiUserCircle } from "react-icons/pi";
import { NavLink } from "react-router-dom";
import { Container, Navigation, NavItem,LinkTo, LogoWrapper } from "./styles";
import { ButtonText } from "../Common/typography";
import Logo from "../Common/logo";
import { API_URL } from "../../constants";
import { Avatar, avatarError } from "../Common/common";

const TopBar = ({ user }) => {
  const [isTopBarVisible, setIsTopBarVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY) {
      // Scrolling down
      setIsTopBarVisible(false);
    } else {
      // Scrolling up
      setIsTopBarVisible(true);
    }
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <Container isVisible={isTopBarVisible}>
      <LogoWrapper> <Logo /></LogoWrapper>

      <nav style={{ flex: 1 }}>
        <Navigation>
        <li>
            <NavLink exact to={{pathname: "/contact", state: {notShowHeader: true}}} activeClassName="active">
              {({ isActive }) =>
                isActive ? (
                  <NavItem
                    hoverBackground="--color-pink9"
                    bakcground="--color-pink"
                  >
                    <ButtonText
                      fontWeight="500"
                      color="--color-white"
                      hoverColor="--color-white"
                    >
                      Kontakt
                    </ButtonText>
                  </NavItem>
                ) : (
                  <NavItem hoverBackground="--transparent06">
                    <ButtonText hoverColor="--primary-text">Kontakt</ButtonText>
                  </NavItem>
                )
              }
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/firma" activeClassName="active">
              {({ isActive }) =>
                isActive ? (
                  <NavItem
                    hoverBackground="--color-pink9"
                    bakcground="--color-pink"
                  >
                    <ButtonText
                      fontWeight="500"
                      color="--color-white"
                      hoverColor="--color-white"
                    >
                      Erhverv
                    </ButtonText>
                  </NavItem>
                ) : (
                  <NavItem hoverBackground="--transparent06">
                    <ButtonText hoverColor="--primary-text">Erhverv</ButtonText>
                  </NavItem>
                )
              }
            </NavLink>
          </li>
          <li>
            <NavLink to="/private" activeClassName="active">
              {({ isActive }) =>
                isActive ? (
                  <NavItem
                    hoverBackground="--color-pink9"
                    bakcground="--color-pink"
                  >
                    <ButtonText
                      fontWeight="500"
                      color="--color-white"
                      hoverColor="--color-white"
                    >
                      Private
                    </ButtonText>
                  </NavItem>
                ) : (
                  <NavItem hoverBackground="--transparent06">
                    <ButtonText hoverColor="--primary-text">Private</ButtonText>
                  </NavItem>
                )
              }
            </NavLink>
          </li>
        </Navigation>
      </nav>

      <SimpleButton style={{ gap: 5 }}>
        <LinkTo to={user ? `/profile/${user.name}` : "/login"}>
          {user.userImage ? (
            <Avatar src={`${API_URL}${user.userImage}`} onError={avatarError}/>
          ) : (
            <PiUserCircle size={32} color="var(--color-pink)"/>
          )}

          <ButtonText className="userText">
            {user.name ? `${user.name} ${user.lastname}` : "Login"}
          </ButtonText>
        </LinkTo>
      </SimpleButton>
    </Container>
  );
};

export default TopBar;
