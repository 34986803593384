import { useState } from "react";
import styled from "styled-components";
import Modal from "../Common/modal";
import Input from "../Common/Input";
import { ButtonWithSpinner } from "../Common/buttons";
import { Paragraph } from "../Common/typography";
import { resetPassword } from "../../api/userService";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "../../toastProvider";
import { PASSWORD_RESET_MESSAGE } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const Form = styled.form`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const NewPassword = ({ onClose }) => {
  const isOpen = true;
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(null);
  const { addToast } = useToast();
  const { token } = useParams();
  const navigate = useNavigate();

  const passwordMutate = useMutation({
    mutationFn: resetPassword,
    mutationKey: ["RESET_TOKEN"],
  });

  const handleChange = (e) => setPassword(e.currentTarget.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    passwordMutate.mutate(
      { password, token },
      {
        onError: (error) => addToast(error?.message, "warning"),
        onSuccess: () => navigate("/login"),
        onSettled: () => {
          setIsLoading(false);
        },
      }
    );
  };

  return (
    <div style={{width: "100%", height: "100%"}}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={"Ny Adgangskode"}
        width={"30rem"}
        marginTop={'5rem'}
      >
        <hr />
        <Paragraph>{PASSWORD_RESET_MESSAGE}</Paragraph>
        <Form onSubmit={handleSubmit}>
          <Input
            required={true}
            label="Indtast din ny adgangskode"
            name="password"
            type="password"
            onchange={handleChange}
            value={password}
          />
          <ButtonWithSpinner
            type="submit"
            width={"18rem !important"}
            bakcground="--color-green"
            color="--color-white"
            style={{ alignSelf: "center" }}
            padding={isLoading ? "1.5rem" : "9px"}
            disabled={isLoading}
            loading={isLoading}
          >
            {!isLoading && "Godkende"}
          </ButtonWithSpinner>
        </Form>
      </Modal>
    </div>
  );
};

export default NewPassword;
