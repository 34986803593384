import { Outlet, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { FaArrowRight } from "react-icons/fa6";
import { IoCallOutline, IoMailOutline } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import TopBar from "../components/topNav/topBar";
import Img from "../assets/house2.png";
import { SimpleButton } from "../components/Common/buttons";
import Search from "../components/search/search";
import {
  StrongTitle,
  Link,
  H4,
  H5,
} from "../components/Common/typography";
import { reklamText } from "../constants";
import { useEffect, useState } from "react";
import { breakpoints } from "../constants";

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const TopContainer = styled.div`
  width: 100%;
`;
const CoresolContainer = styled.div`
  display: ${({ notShow }) => (notShow ? "none" : "flex")};
  position: relative;
  justify-content: space-between;
  padding-top: 2.5rem;
  padding-left: 8rem;
  padding-right: 7rem;
  position: relative;
  width: 100%;
  height: 26.5rem;
  background-color: var(--color-pink-light);
  border-bottom: 1px solid #d4e8d5;
  margin-top: 60px;
  img {display: none}
  h1 {font-size: 30px}
  @media (min-width: ${breakpoints.sm}) {
    padding-left: 2rem;
    padding-right: 1rem;
    img {
      display: none;
    }
  }
  @media (min-width: ${breakpoints.md}) {
    img {display: block}
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-top: 2.5rem;
    padding-left: 8rem;
    padding-right: 7rem;
  }
`;
const OutletContainer = styled.div`
  margin: 0 auto;
  padding-top: 5rem;
  width: 100%;
`;
const TitleContiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

const Highlight = styled.span`
  font-size: 40;
  color: ${(props) => props.color || "var(--color-pink)"};
`;

const Imge = styled.img`
  width: 30rem;
  height: auto;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 5px;
  justify-content: start;
  margin-bottom: -5px;
`;

const LogoText = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: var(--secondary-text);
  margin-top: 1rem;
  margin-bottom: 0;
`;

const SearchContainer = styled.div`
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translate(-50%);
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 5rem;
  margin-top: 5rem;
  padding-bottom: 2rem;
  div {
    text-align: center;
  }

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    div{
      text-align: left;
    }
  }
`;

const Home = ({ user }) => {
  const [hideHeader, setHideHeader] = useState(false);
  const  { id } = useParams();
  const navigate = useNavigate();

  useEffect(()=> {
    navigate("/private");
  }, []);

  return (
    <MainContainer>
      <TopContainer style={{display: id ? 'none' : 'block'}}>
        <TopBar user={user} />
        <CoresolContainer notShow={hideHeader}>
          <TitleContiner>
            <IconContainer>
              <IoHome
                size={16}
                color="var(--color-pink)"
                style={{ marginBottom: 5 }}
              />
              <LogoText>Estate Build</LogoText>
            </IconContainer>
            <StrongTitle>
              Hos <Highlight>Estate</Highlight>
              <Highlight color="#007bff">build</Highlight>
            </StrongTitle>
            <StrongTitle>Finder du boliger til leje</StrongTitle>
            <H4>{reklamText}</H4>
            <SimpleButton
              bakcground="--color-pink"
              color="--color-white"
              fontSize="16px"
              width="150px"
              weight="600"
              style={{ marginTop: "1rem" }}
            >
              Læse mere
              <FaArrowRight />
            </SimpleButton>
          </TitleContiner>
          <Imge src={Img} />
          <SearchContainer>
            <Search />
          </SearchContainer>
        </CoresolContainer>
      </TopContainer>

      <OutletContainer id="outlet-container">
        <Outlet context={{hide: setHideHeader}} />
      </OutletContainer>

      <Footer>
        <div>
          <H5>Finsensvej 20, 2000 Frederiksberg</H5>
          <Link href="tel:+4525129947">
            <IoCallOutline /> +45 25 12 99 47
          </Link>
          <Link href="mailto:kontakt@estatebuild.dk">
            <IoMailOutline /> kontakt@estatebuild.dk
          </Link>
          <Link href="tel:+4540494464">
            <IoCallOutline />
            +45 40 49 44 64
          </Link>
        </div>
        <div>
          <H5>Åbningstider</H5>
          <H5>Mandag - Fredag</H5>
          <H4>09.00 - 18.00</H4>
        </div>
        <div>
          <Link href="#">Persondatabehandling</Link>
          <Link href="#">FAQ</Link>
        </div>
      </Footer>
    </MainContainer>
  );
};

export default Home;
