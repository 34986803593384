import APIclient from "./http";

const apartmentService = new APIclient('apartment');

export const addApartment = async (data) => {
    const response = await apartmentService.postFormData(data,"", { withCredentials: true });
    return response;
  };

export const getApartments = async () => {
    const response = await apartmentService.get();
    return response;
  };

export const updateApartment = async (data) => {
    const response = await apartmentService.updateFormData(data, `${data._id}`, { withCredentials: true });
    return response;
  };

export const deleteApartment = async (data) => {
    const response = await apartmentService.delete(`${data._id}`, { withCredentials: true });
    return response;
  };