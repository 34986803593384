import React from "react";
import styled, { keyframes } from "styled-components";
import { useToast } from "../toastProvider";
import { RoundButton } from "./Common/buttons";
import { MdClose, MdOutlineInfo } from "react-icons/md";

const FadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
const FadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

const Container = styled.div`
  position: fixed;
  top: 20px;
  right: 50%;
  transform: translateX(50%);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Toastify = styled.div`
  background-color: ${({type}) => type == "warning" ? "var(--color-red)" : "var(--color-green8)"};
  min-width: 20rem;
  color: white;
  padding: 10px 20px;
  border-radius: 15px;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  opacity: 1;
  animation: ${FadeIn} 0.5s forwards, ${FadeOut} 0.5s 4.5s forwards;
`;
const Content = styled.p`
  font-size: 17px;
  color: var(--color-white)
`;

const Toast = () => {
  const { toasts, removeToast } = useToast();

  return (
    <Container>
      {toasts.map((toast) => (
        <Toastify key={toast.id} type={toast.type}>
          <MdOutlineInfo color="var(--color-white)" size={32}/>
          <Content>{toast.message}</Content>
          <RoundButton
            color="--color-white"
            onClick={() => removeToast(toast.id)}
          >
            <MdClose size={22}/>
          </RoundButton>
        </Toastify>
      ))}
    </Container>
  );
};

export default Toast;
