import styled from "styled-components";
import { useState, useEffect } from "react";
import { breakpoints } from "../../constants";

const { sm, md, lg, xl } = breakpoints;

export const Title = styled.p`
  letter-spacing: -0.2px;
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}` : "18px"};
  font-weight: ${({ fontWeight }) => (`${fontWeight}` || "500")};
  color: ${({ color }) => (color ? `var(${color})` : "var(--primary-text)")};
  text-transform: capitalize;
  text-shadow: var(--primary-text-shadow);
  margin: 0;
`;

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 15px;
  color: ${({ color }) => (color ? `var(${color})` : "var(--secondary-text)")};
`;

export const Caption = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: var(--secondary-text);
`;



export const StrongTitle = styled.h1`
  margin-bottom: 0;
  margin-top: 0;
  color: var(primary-text-btn);
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}` : "23px"};
  font-weight: 700;
  @media (min-width: ${lg}) {
    font-size: ${({ fontSize }) => fontSize ? `${fontSize}` : "33px"};
  }
`;

export const BoldText = styled.p`
  font-size: 17px;
  font-weight: 500;
  color: var(--secondary-text);
  margin-top: 0;
`;

export const Text = styled.p`
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}` : "16px"};
  font-weight: ${({ fontWeight }) => (fontWeight ? `${fontWeight}` : "400")};
  color: ${({ color }) => (color ? `var(${color})` : "var(--primary-text)")};
  text-shadow: var(--primary-text-shadow);
`;


export const Link = styled.a`
  font-size: 15px;
  font-weight: ${({ fontWeight }) => fontWeight ? `${fontWeight}` : "500"};
  color: ${({ color }) => (color ? `var(${color})` : "var(--primary-text)")};
  text-shadow: var(--secondary-text-shadow);
  display: block;
  text-decoration: none;
`;
export const ButtonText = styled(Text)`
  margin: 0px;
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}` : "15.5px"};
  font-weight: ${({ fontWeight }) => fontWeight ? `${fontWeight}` : "500"};
  color: ${({ color }) => (color ? `var(${color})` : "var(--secondary-text)")};
  text-shadow: var(--secondary-text-shadow);
  &:hover{color: ${({ hoverColor }) => (hoverColor ? `var(${hoverColor})` : "")};
`;
export const H6 = styled(Text)`
  font-size: 13px;
  font-weight: ${({ fontWeight }) => fontWeight ? `${fontWeight}` : "500"};
  color: ${({ color }) => (color ? `var(${color})` : "var(--primary-text)")};
  text-shadow: var(--secondary-text);
  filter: var(--filter-secondary-text);
`;
export const H5 = styled(Text)`
  font-size: 15px;
  font-weight: ${({ fontWeight }) => fontWeight ? `${fontWeight}` : "500"};
  color: ${({ color }) => (color ? `var(${color})` : "var(--primary-text)")};
  text-shadow: var(--secondary-text-shadow);
  
`;
export const H4 = styled(Text)`
  font-size: 17px;
  font-weight: ${({ fontWeight }) => fontWeight ? `${fontWeight}` : "500"};
  color: ${({ color }) => (color ? `var(${color})` : "var(--primary-text)")};
  text-shadow: var(--secondary-text-shadow);

`;
export const H3 = styled(Text)`
  font-size: 19px;
  font-weight: ${({ fontWeight }) => fontWeight ? `${fontWeight}` : "500"};
  color: ${({ color }) => (color ? `var(${color})` : "var(--secondary-text)")};
  text-shadow: var(--primary-text-shadow);

`;
export const H2 = styled(Text)`
  font-size: 22px;
  font-weight: ${({ fontWeight }) => fontWeight ? `${fontWeight}` : "400"};
  color: ${({ color }) => (color ? `var(${color})` : "var(--secondary-text)")};
  text-shadow: var(--primary-text-shadow);
`;

const TextConcut = ({ content }) => {
  let concatedText = content;

  if (content && content.length > 20) {
    concatedText = content.slice(0, 20) + '...';
  }

  return <Text>{concatedText}</Text>;
};

export default TextConcut;
