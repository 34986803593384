import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FadeInNoSlide } from "../components/Common/animations";
import { useNavigate, useOutletContext } from "react-router-dom";
import { StrongTitle, H4, ButtonText } from "../components/Common/typography";
import { breakpoints } from "../constants";
import Input from "../components/Common/Input";
import { TextArea } from "../components/Common/textArea";
import { ButtonWithSpinner } from "../components/Common/buttons";
import { useMutation } from "@tanstack/react-query";
import { contact } from "../api/userService";
import { useToast } from "../toastProvider";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: ${FadeInNoSlide} 0.5s;
  padding: 1rem;
  gap: 5rem;
  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    padding: 0 2rem;
  }
  @media (main-width: ${breakpoints.lg}) {
    padding: 0 8rem;
  }
`;
const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  margin: 0 2rem;
`;
const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 1rem;
`;
const Form = styled.form``;
const Label = styled.p`
  font-size: 15px;
  color: var(--secondary-text);
  margin-bottom: 0px;
  font-weight: 500;
  margin-top: 10px;
`;

export default function Contact() {
  const [data, setData] = useState({ isLoading: false });
  const { hide } = useOutletContext();
  const { addToast } = useToast();
  const navigate = useNavigate();
  useEffect(() => {
    if (hide) hide(true);
  }, [hide]);

  const sendMessage = useMutation({
    mutationFn: contact,
    mutationKey: ["CONTACT"],
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setData((pre) => ({ ...pre, isLoading: true }));

    sendMessage.mutate(data, {
      onError: (error) => addToast(error?.message, "warning"),
      onSuccess: (response) => {
        if (response) {
          setData({ isLoading: false, subject: '', name: '', email: '', content: '' });
          addToast("Tak vi har fåer din besked.", "info");
        }
      },
      onSettled: () => setData({ isLoading: false }),
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.currentTarget;
    setData((pre) => ({ ...pre, [name]: value }));
  };

  return (
    <Container>
      <LeftPanel>
        <StrongTitle>
          Vi vil gøre alt, hvad vi kan for at få din tilfredshed.
        </StrongTitle>
        <H4 color="--secondary-text">
          Vi er her for at hjælpe dig, og vi vil elske at komme i kontakt med
          dig
        </H4>
        <H4 color="--secondary-text">Du hører fra os hurtigst muligt.</H4>
      </LeftPanel>
      <RightPanel>
        <Form onSubmit={handleSubmit}>
          <Label>Emne</Label>
          <Input
            labelColor="--color-light"
            label="Skriv emnet here."
            name="subject"
            type="text"
            onchange={handleChange}
            value={data?.subject}
            border="--primary-border"
            background="--transparent"
            width="100%"
            required
          />
          <Label>Navn</Label>
          <Input
            labelColor="--color-light"
            label="Skriv dit navn."
            name="name"
            type="text"
            onchange={handleChange}
            value={data?.name}
            border="--primary-border"
            background="--transparent"
            width="100%"
            required
          />
          <Label>Email</Label>
          <Input
            labelColor="--color-light"
            label="Skriv din email"
            name="email"
            type="email"
            onchange={handleChange}
            value={data?.email}
            border="--primary-border"
            background="--transparent"
            width="100%"
            required
          />
          <Label>Besked</Label>
          <TextArea
            rows={7}
            placeholder="Skriv din besked here."
            onChange={handleChange}
            value={data?.content}
            name="content"
            required
          />

          <ButtonWithSpinner
            style={{ marginTop: "20px" }}
            color="--color-white"
            hoverColor="--color-white"
            bakcground="--color-pink"
            width="150px"
            radius="7px"
            hoverBackground="--color-pink9"
            padding="9px"
            type="submit"
            disabled={data.isLoading}
            loading={data.isLoading}
          >
            {!data.isLoading && (
              <ButtonText fontWeight="600" color="--color-white">
                Send
              </ButtonText>
            )}
          </ButtonWithSpinner>
        </Form>
      </RightPanel>
    </Container>
  );
}
