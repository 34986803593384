import { useState } from "react";
import styled from "styled-components";
import pic from "../assets/safe.jpg";
import Logon from "../components/auth/logon";
import SignUp from "../components/auth/register";
import { breakpoints } from "../constants";

const Container = styled.div`
  position: relative;
  background-color: var(--web-wash);
`;

const LoginContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 20%);
  width: 20rem;
  height: 32rem;
  box-shadow: var(--cart-boxshadow);
  background-color: var(--color-white);
  border-radius: var(--cart-radius);
  @media (min-width: ${breakpoints.md}) { 
    width: 40rem;
  }
  @media (min-width: ${breakpoints.lg}) { 
    width: 50rem;
  }
`;

const Img = styled.img`
  height: 100%;
  transition: transform 0.5s ease-in-out;
  width: 25rem;
`;
const Panel = styled.div`
  position: relative;
  display: none;
  @media (min-width: ${breakpoints.lg}) { 
    display: block;
  }

`;

const LeftPanel = styled.div`
  padding: 1rem;
  text-align: center;
  width: 100%;
`;

const Highlight = styled.span`
  font-size: 46;
  font-weight: 700;
  color: ${(props) => props.color || "green"};
`;

const Header = styled.div`
  width: 100%;
  textalign: center;
  paddingbottom: 3rem;
  paddingtop: 2rem;
  margin-bottom: 3rem;
`;

const OverlayText = styled.p`
  position: absolute;
  padding: 1rem;
  bottom: 15px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) =>
    isVisible ? "translateY(15px)" : "translateY(110px)"};
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
`;

const Login = () => {
  const [isRegister, setRegister] = useState(false);
  const handleClick = () => setRegister(!isRegister);

  return (
    <Container>
      <LoginContainer>
        <LeftPanel>
          <Header>
            <Highlight>Estate</Highlight>
            <Highlight color="#007bff">Build</Highlight>
          </Header>
          {isRegister ? (
            <SignUp back={handleClick} />
          ) : (
            <Logon togglerRegister={handleClick} />
          )}
        </LeftPanel>
        <Panel>
          <Img src={pic} />
          <OverlayText isVisible={isRegister}>
            Ved at oprette en bruger i Min Esatebuild accepterer du Estatebuilds
            retningslinjer for brugen af personlige oplysninger.
          </OverlayText>
        </Panel>
      </LoginContainer>
    </Container>
  );
};

export default Login;
