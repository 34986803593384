import { useState } from "react";
import { H2 } from "../Common/typography";
import { updateApartment } from "../../api/httpServices";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "../../toastProvider";
import FileManager from "./fileManager";
import styled from "styled-components";
import Form from "./form";
import { SubmitButton } from "./styles";
import { SimpleButton } from "../Common/buttons";
import useApartmentStore from "../../store/useApartmentStore";
import { breakpoints } from "../../constants";

const Container = styled.div`
  overflow: auto;
  padding-top: 2rem;
  
  @media (min-width: ${breakpoints.md}) {
    padding-top: 3rem;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

export default function UpdateApartment({ aprt, addState }) {
  const { upApartment} = useApartmentStore();
  const { addToast } = useToast();
  const [data, setData] = useState({
    isLoading: false,
    ...aprt,
  });

  const mutateApartment = useMutation({
    mutationFn: updateApartment,
    mutationKey: ["UPDATE_APARTMENT"],
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    setData((pre) => ({ ...pre, isLoading: true }));
    mutateApartment.mutate(data, {
      onError: (error) => addToast(error.response?.data?.message, "warning"),
      onSuccess: (data) => {
        setData({});
        upApartment(data);
        addState(null);
      },
      onSettled: () => setData((pre) => ({ ...pre, isLoading: false })),
    });
  };

  return (
    <Container>
      <H2 style={{ marginBottom: "1.5rem" }}>Opdatere lejemålet</H2>
      <FileManager data={data} setData={setData} />
      <Form data={data} setData={setData} handleSubmit={handleSubmit}>
        <ButtonsWrapper>
          <SubmitButton maxWidth="200px" loading={data.isLoading} type="submit">Gem</SubmitButton>
          <SimpleButton
            bakcground="--transparent1"
            radius="7px"
            padding="0.5rem 0.8rem"
            fontSize="16px"
            style={{marginTop: 10}}
            hoverBackground="--transparent2"
            hoverColor="--primary-text"
            onClick={() => addState(null)}
          >
            Anuallere
          </SimpleButton>
        </ButtonsWrapper>
      </Form>
    </Container>
  );
}
